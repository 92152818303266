import { useSignupUser } from "@/authentication/signup/util/useSignupUser"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { GiftProductFormStore } from "@/product/gift/GiftProductForm"
import GiftProductFormGifterSection from "@/product/gift/registration/GiftProductFormGifterSection"
import GiftProductFormReceiverSection from "@/product/gift/registration/GiftProductFormReceiverSection"
import ProductRegistrationCheckboxes from "@/product/register/steps/ProductRegistrationCheckboxes"
import RedirectToSignInLink from "@/user/common/prompt-to-sign-in-link/PromptToSignInLink"
import useUserTimezone from "@/user/util/useUserTimezone"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoDivider, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useState } from "react"

interface Props {
  form: GiftProductFormStore
}

const GiftProductFormRegistrationStep = observer<Props>((props) => {
  const { form } = props

  const registrationDrawer = useGlobalDrawer("registration")
  const signupUser = useSignupUser()
  const { authUser } = useAuthUser()
  const userTimezone = useUserTimezone()
  const activeOrganization = useActiveOrganization()

  // If the user already is in the community, skip the terms
  if (activeOrganization?.viewerMembership?.id) {
    form.state.hasAcceptedTerms = true
  }
  const experienceLabel = useLabel("experience")
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <form onSubmit={handleSubmit}>
      <DiscoText variant={"heading-md"} marginBottom={1}>
        {`Gift This ${experienceLabel.singular}`}
      </DiscoText>
      {form.state.gifter.isNewUser && (
        <Grid container spacing={1}>
          <RedirectToSignInLink testid={"GiftProductFormRegistrationStep.login-link"} />
        </Grid>
      )}

      <DiscoDivider marginBottom={3} />
      <GiftProductFormGifterSection form={form} />
      <DiscoDivider marginTop={3} marginBottom={3} />
      <GiftProductFormReceiverSection form={form} />
      <DiscoDivider marginTop={0} marginBottom={3} />

      {/* Hide if already part of the community */}
      {!activeOrganization?.viewerMembership?.id && (
        <ProductRegistrationCheckboxes
          giftStore={form}
          testid={"GiftProductFormRegistrationStep"}
        />
      )}

      <Grid container justify={"flex-end"}>
        <Grid item>
          <DiscoButton
            type={"submit"}
            disabled={!canSubmitForm()}
            shouldDisplaySpinner={isSubmitting}
            data-testid={"GiftProductFormRegistrationStep.submit-button"}
          >
            {"Next Step"}
          </DiscoButton>
        </Grid>
      </Grid>
    </form>
  )

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    const { gifter } = form.state
    if (!canSubmitForm()) return

    try {
      setIsSubmitting(true)
      // When gifting as a new user, sign up the gifter first.
      if (gifter.isNewUser && !authUser) {
        await signupUser({
          email: gifter.email,
          firstName: gifter.firstName,
          lastName: gifter.lastName,
          timezone: userTimezone,
        })
      }

      form.state.step = "payment"

      // Set the drawer params if available
      if (registrationDrawer.isOpen)
        registrationDrawer.setParams({ registrationStep: "payment" })
    } catch (err) {
      displayErrorToast(err)
    } finally {
      setIsSubmitting(false)
    }
  }

  function canSubmitForm(): boolean {
    const { gifter, confirmRecipientEmail } = form.state
    // Existing gifter must be logged in.
    if (!gifter.isNewUser) {
      if (!authUser) return false
    }
    // New gifter must complete form.
    else if (
      !gifter.firstName ||
      !gifter.lastName ||
      !gifter.email ||
      !gifter.confirmEmail ||
      gifter.email !== gifter.confirmEmail
    ) {
      return false
    }
    if (form.state.recipientEmail !== confirmRecipientEmail) return false
    // Must provide gift receiver and message
    if (!form.state.message || !form.state.recipientEmail) return false
    // Must accept terms.
    if (!form.state.hasAcceptedTerms) return false
    // If currently submitting
    if (isSubmitting) return false
    // Otherwise can submit
    return true
  }
})

export default GiftProductFormRegistrationStep
