import { DefaultLightThemeProvider } from "@/core/context/CustomThemeProvider"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const MemberReportDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "member-report-drawer-content" */ "@/product/reports/drawer/MemberReportDrawerContent"
    )
)

function MemberReportDrawer() {
  const membersLabel = useLabel("product_member")
  const drawer = useGlobalDrawer("memberReport")
  if (!drawer.params.productMembershipId?.length) return null

  return (
    <DefaultLightThemeProvider>
      <DiscoDrawer
        title={`${membersLabel.singular} Report`}
        open={drawer.isOpen}
        onClose={drawer.close}
      >
        <MemberReportDrawerContent onClose={drawer.close} />
      </DiscoDrawer>
    </DefaultLightThemeProvider>
  )
}

export default MemberReportDrawer
