import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const ExperienceSettingsDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "experience-settings-drawer-content" */ "@/product/settings/ExperienceSettingsDrawerContent"
    )
)

function ExperienceSettingsDrawer() {
  const globalDrawer = useGlobalDrawer("experienceSettings")
  const classes = useStyles()

  const modal = useInitUnsavedChangesModalContext()

  return (
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer
        title={"Settings"}
        open={globalDrawer.isOpen}
        onClose={handleCloseDrawer}
        size={"large"}
        maxSize={"xl"}
        containerClasses={{ drawerContainer: classes.drawer }}
      >
        {/* Content */}
        {globalDrawer.params.drawerExperienceId && (
          <ExperienceSettingsDrawerContent
            experienceId={globalDrawer.params.drawerExperienceId}
          />
        )}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )

  function handleCloseDrawer() {
    modal.handleLeave({
      onLeave: () => globalDrawer.close(),
    })
  }
}

const useStyles = makeUseStyles({
  drawer: {
    padding: 0,
    // Hiding overflow so ExperienceSettingsForm can use sticky footer
    overflowY: "hidden",
  },
})

export default ExperienceSettingsDrawer
