/**
 * @generated SignedSource<<6702c826731441b3b08a8b4ea5a786fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExperienceSettingsGroupsTabFragment$data = {
  readonly id: string;
  readonly defaultMemberGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateMemberGroupButtonFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CourseGroupsListFragment">;
  readonly " $fragmentType": "ExperienceSettingsGroupsTabFragment";
};
export type ExperienceSettingsGroupsTabFragment$key = {
  readonly " $data"?: ExperienceSettingsGroupsTabFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceSettingsGroupsTabFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceSettingsGroupsTabFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MemberGroup",
      "kind": "LinkedField",
      "name": "defaultMemberGroup",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateMemberGroupButtonFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseGroupsListFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "7c3ec687aa6528113bceb0aa3b779821";

export default node;
