import UnenrollProductMemberButton from "@/product/member/admin/enrollment/UnenrollProductMemberButton"
import { ExperienceAdminsListItemFragment$key } from "@/product/settings/__generated__/ExperienceAdminsListItemFragment.graphql"
import OrganizationRoleTag from "@/role/OrganizationRoleTag"
import ProductRoleTag from "@/role/ProductRoleTag"
import { useCanManageProductRole, useProductRoleLabel } from "@/role/roleUtils"
import UpdateProductRoleButton from "@/role/UpdateProductRoleButton"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCheckbox, DiscoIconButtonSkeleton, DiscoTable } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import DiscoTableRow from "@disco-ui/table/row/DiscoTableRow"
import { DiscoTagSkeleton } from "@disco-ui/tag/DiscoTag"
import { TableCell, useMediaQuery, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  productMembershipKey: ExperienceAdminsListItemFragment$key
  selected: boolean
  onToggle: (productMembership: { id: string; memberId: string }) => void
}

function ExperienceAdminsListItem({
  productMembershipKey,
  onToggle,
  selected,
  testid = "ExperienceAdminsListItem",
}: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const isXsScreen = useMediaQuery(() => theme.breakpoints.down("xs"))

  const productMembership = useFragment<ExperienceAdminsListItemFragment$key>(
    graphql`
      fragment ExperienceAdminsListItemFragment on ProductMembership {
        id
        role
        member {
          id
          fullName
          ...ProfileAvatarWithDetailsFragment
        }
        product {
          ...usePermissionsFragment
        }
        organizationMembership {
          email
          role
        }
        ...UnenrollProductMemberButtonFragment
        ...UpdateProductRoleButtonFragment
      }
    `,
    productMembershipKey
  )
  const permissions = usePermissions(productMembership.product)
  const roleLabel = useProductRoleLabel(productMembership.role)
  const canManageRole = useCanManageProductRole(
    productMembership.product,
    productMembership.role
  )
  const updateRole = productMembership.role === "manager" ? "instructor" : "manager"
  const updateRoleLabel = useProductRoleLabel(updateRole)
  const canManageUpdateRole = useCanManageProductRole(
    productMembership.product,
    updateRole
  )

  if (!productMembership) return null

  return (
    <DiscoTableRow testid={`${testid}.${productMembership.member.fullName}`}>
      <TableCell>
        {permissions.has("emails.manage") && (
          <DiscoCheckbox
            className={classes.checkbox}
            label={null}
            onChange={() =>
              onToggle({
                id: productMembership.id,
                memberId: productMembership.member.id,
              })
            }
            checked={selected}
          />
        )}
      </TableCell>
      <TableCell width={"99%"}>
        <ProfileAvatarWithDetails
          userKey={productMembership.member}
          details={productMembership.organizationMembership.email}
          hideAvatar={isXsScreen}
        />
      </TableCell>
      {!isXsScreen && (
        <TableCell>
          <OrganizationRoleTag
            testid={`${testid}.organization-role`}
            organizationRole={productMembership.organizationMembership.role}
          />
        </TableCell>
      )}
      <TableCell>
        <ProductRoleTag
          testid={`${testid}.product-role`}
          productRole={productMembership.role}
        />
      </TableCell>
      <TableCell>
        <DiscoMoreActionsDropdown testid={`${testid}.more-actions`} rotateIcon>
          <UpdateProductRoleButton
            productMembershipKey={productMembership}
            updateRole={updateRole}
          >
            {(props) => (
              <DiscoDropdownItem
                {...props}
                testid={`${testid}.make-${updateRole}`}
                title={`Change to ${updateRoleLabel.singular}`}
                disabled={!canManageRole || !canManageUpdateRole}
              />
            )}
          </UpdateProductRoleButton>
          <UnenrollProductMemberButton productMembershipKey={productMembership}>
            {(props) => (
              <DiscoDropdownItem
                {...props}
                testid={`${testid}.remove`}
                title={`Remove ${roleLabel.singular}`}
                disabled={!canManageRole}
              />
            )}
          </UnenrollProductMemberButton>
        </DiscoMoreActionsDropdown>
      </TableCell>
    </DiscoTableRow>
  )
}

const useStyles = makeUseStyles({
  checkbox: {
    marginRight: 0,
  },
})

export const ExperienceAdminsListItemSkeleton = () => {
  return (
    <DiscoTable.Row>
      <TableCell>
        <Skeleton variant={"rect"} width={24} height={24} />
      </TableCell>
      <TableCell width={"50%"}>
        <Skeleton variant={"rect"} height={40} />
      </TableCell>
      <TableCell>
        <DiscoTagSkeleton />
      </TableCell>
      <TableCell>
        <DiscoTagSkeleton />
      </TableCell>
      <TableCell>
        <DiscoIconButtonSkeleton width={30} height={30} />
      </TableCell>
    </DiscoTable.Row>
  )
}

export default observer(ExperienceAdminsListItem)
