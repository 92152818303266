import { invalidateRoleAvatarStackProductRoles } from "@/admin/members/roles/RoleAvatarStack"
import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { UnenrollProductMemberButtonFragment$key } from "@/product/member/admin/enrollment/__generated__/UnenrollProductMemberButtonFragment.graphql"
import { UnenrollProductMemberButtonMutation } from "@/product/member/admin/enrollment/__generated__/UnenrollProductMemberButtonMutation.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import Relay from "@/relay/relayUtils"
import { useProductRoleLabel } from "@/role/roleUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoText, DiscoTextButton } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import React, { useState } from "react"
import { useFragment } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"

type UnenrollProductMemberButtonProps = {
  productMembershipKey: UnenrollProductMemberButtonFragment$key
  children: OverridableDiscoButtonProps["children"]
  membershipConnectionId?: string
}

const UnenrollProductMemberButton: React.FC<UnenrollProductMemberButtonProps> = (
  props
) => {
  const { productMembershipKey, membershipConnectionId, children } = props
  const [isModalOpen, setModal] = useState(false)

  const productMembership = useFragment<UnenrollProductMemberButtonFragment$key>(
    graphql`
      fragment UnenrollProductMemberButtonFragment on ProductMembership {
        id
        productId
        role
        member {
          fullName
        }
        product {
          organization {
            id
            stripeDashboardURL
          }
        }
      }
    `,
    productMembershipKey
  )

  const form = useFormStore<UnenrollProductMemberButtonMutation>(
    graphql`
      mutation UnenrollProductMemberButtonMutation($input: UnenrollProductMemberInput!) {
        response: unenrollProductMember(input: $input) {
          productMembership {
            id
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      productMembershipId: productMembership.id,
    }
  )
  const roleLabel = useProductRoleLabel(productMembership.role)
  const experienceLabel = useLabel("experience")
  const isTeacher =
    productMembership.role === "manager" || productMembership.role === "instructor"

  return (
    <>
      <OverridableDiscoButton onClick={() => setModal(true)} {...props}>
        {children}
      </OverridableDiscoButton>
      <DiscoWarningModal
        testid={"UnenrollProductMemberButton.unenroll-member-warning-modal"}
        isOpen={isModalOpen}
        onClose={() => setModal(false)}
        title={`Are you sure you want to unenroll ${
          productMembership.member.fullName || `this ${roleLabel.singular}`
        }?`}
        description={
          isTeacher ? (
            `Are you sure you want to remove this ${roleLabel.singular}?`
          ) : (
            <>
              <DiscoText>
                {`They will no longer be able to access the ${experienceLabel.singular}.`}
              </DiscoText>
              {productMembership.product?.organization.stripeDashboardURL && (
                <>
                  <DiscoText>
                    {"If you would like to issue a refund, please do so from your "}
                  </DiscoText>
                  <DiscoTextButton
                    data-testid={"PayoutSettings.stripe-link"}
                    target={"_blank"}
                    href={productMembership.product.organization.stripeDashboardURL}
                  >
                    {"Stripe Dashboard"}
                  </DiscoTextButton>
                </>
              )}
            </>
          )
        }
        confirmationButtonProps={{
          onClick: handleUnenrollMember,
          shouldDisplaySpinner: form.isSubmitting,
          children: `Yes, unenroll ${roleLabel.singular}`,
        }}
        modalContentLabel={"Unenroll member dialog"}
      />
    </>
  )

  async function handleUnenrollMember() {
    const { didSave } = await form.submit(
      { productMembershipId: form.state.productMembershipId },
      {
        updater: (store) => {
          if (!membershipConnectionId) return
          const connection = store.get(membershipConnectionId)
          if (!connection) return
          Relay.deleteNodeFromConnection(connection, productMembership.id)
        },
      }
    )
    if (!didSave) return

    // Update Relay store
    commitLocalUpdate(RelayEnvironment, (store) => {
      const productRecord = store.get(productMembership.productId)
      if (productRecord) {
        // Remove product membership from product members list
        const connections = ConnectionHandler.getConnections(
          productRecord,
          "ProductMembersList__productMemberships"
        )
        connections.forEach((connection) => {
          ConnectionHandler.deleteNode(connection, productMembership.id)
        })

        // Delete product membership from relevant productMemberships connections
        const productMembershipsConnection =
          productRecord.getLinkedRecord("productMemberships")
        if (productMembershipsConnection) {
          Relay.deleteNodeFromConnection(
            productMembershipsConnection,
            productMembership.id
          )
        }

        // Invalidate the ExperienceAdminsList connection so it refetches
        ConnectionHandler.getConnections(
          productRecord,
          "ExperienceAdminsList__productMemberships"
        ).forEach((connection) => connection.invalidateRecord())
      }

      invalidateRoleAvatarStackProductRoles(
        store,
        productMembership.product.organization.id
      )
    })

    displaySuccessToast({
      message: `Successfully unenrolled ${roleLabel.singular}`,
      testid: "UnenrollProductMemberButton.success-toast",
    })
    setModal(false)
  }
}

export default UnenrollProductMemberButton
