import { GiftProductFormStore } from "@/product/gift/GiftProductForm"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormLabel, DiscoInput, DiscoText } from "@disco-ui"
import classNames from "classnames"
import { observer } from "mobx-react-lite"

interface Props {
  form: GiftProductFormStore
}

const GiftProductFormReceiverSection = observer<Props>((props) => {
  const { form } = props
  const classes = useStyles()

  return (
    <>
      <DiscoFormLabel>{"Recipient"}</DiscoFormLabel>
      <DiscoText marginBottom={2}>
        {
          "After payment, the recipient of the gift will receive your personalized message and a link to claim their gift."
        }
      </DiscoText>
      <DiscoInput
        fullWidth
        placeholder={"Recipient email"}
        name={"receiver-email"}
        value={form.state.recipientEmail || ""}
        type={"email"}
        onChange={(e) => (form.state.recipientEmail = e.target.value)}
        data-testid={"GiftProductFormReceiverSection.email"}
        error={Boolean(form.errorsByField.recipientEmail || form.errorsByField.user)}
        className={classes.label}
      />
      <DiscoInput
        fullWidth
        placeholder={"Confirm recipient email"}
        name={"confirm-receiver-email"}
        value={form.state.confirmRecipientEmail || ""}
        type={"email"}
        onChange={(e) => (form.state.confirmRecipientEmail = e.target.value)}
        data-testid={"GiftProductFormReceiverSection.confirm-email"}
        error={Boolean(
          form.state.confirmRecipientEmail &&
            form.state.confirmRecipientEmail !== form.state.recipientEmail
        )}
        className={classes.label}
      />
      <DiscoInput
        fullWidth
        multiline
        placeholder={"Gift message"}
        rows={6}
        value={form.state.message || ""}
        onChange={(e) => (form.state.message = e.target.value)}
        data-testid={`GiftProductFormReceiverSection.message-input`}
        error={Boolean(form.errorsByField.message)}
        className={classNames(classes.label, classes.message)}
      />
    </>
  )
})

const useStyles = makeUseStyles((theme) => ({
  label: { marginBottom: theme.spacing(2) },
  message: { padding: theme.spacing(2, 1.25) },
}))

export default GiftProductFormReceiverSection
