import { ProductRole } from "@/admin/members/roles/__generated__/RoleAvatarStackQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useProductRoleDescription, useProductRoleLabel } from "@/role/roleUtils"
import { DiscoText } from "@disco-ui"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & {
  productRole: ProductRole
  products?: { id: string; name: string }[]
}

export default function ProductRoleTag(props: Props) {
  const { productRole, testid = "ProductRoleTag", products } = props
  const roleLabel = useProductRoleLabel(productRole)
  const theme = useTheme()
  const description = useProductRoleDescription(productRole)
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()
  const productLabel = useLabel("experience")

  const productCount = products?.length || 0
  const displayProducts = products?.slice(0, 5)
  const moreCount = productCount - (displayProducts?.length || 0)
  const { backgroundColor, dividerColor } = getColors()

  return (
    <DiscoTag
      testid={`${testid}.${productRole}`}
      name={roleLabel.singular}
      backgroundColor={backgroundColor}
      dividerColor={dividerColor}
      dividerVariant={"full-height"}
      middleTooltip={getDescription()}
      rightButton={
        productCount > 0 && <DiscoText variant={"body-xs-600"}>{productCount}</DiscoText>
      }
      rightButtonTooltip={
        displayProducts?.length ? (
          <>
            {displayProducts.map(({ id, name }) => (
              <DiscoText key={id} variant={"body-xs"} component={"p"}>
                {name}
              </DiscoText>
            ))}
            {moreCount > 0 && (
              <DiscoText
                variant={"body-xs"}
                component={"p"}
              >{`+${moreCount} more`}</DiscoText>
            )}
          </>
        ) : null
      }
    />
  )

  function getColors(): { backgroundColor: string; dividerColor: string } {
    const isDark = theme.palette.type === "dark"
    switch (productRole) {
      case "manager":
        return {
          backgroundColor: theme.palette.groovy.orange[isDark ? 600 : 100],
          dividerColor: theme.palette.groovy.orange[isDark ? 500 : 200],
        }
      case "instructor":
        return {
          backgroundColor: isDark ? "#8f7000" : theme.palette.groovy.yellow[100],
          dividerColor: theme.palette.groovy.yellow[isDark ? 500 : 300],
        }
      case "member":
      default:
        return {
          backgroundColor: theme.palette.groovy.purple[isDark ? 600 : 100],
          dividerColor: theme.palette.groovy.purple[isDark ? 500 : 200],
        }
    }
  }

  function getDescription() {
    // Use the custom description if set
    if (roleLabel.description) return roleLabel.description

    // Only show the permissions description to people with any role > member
    if (
      (activeProduct?.viewerRole && activeProduct.viewerRole !== "member") ||
      (activeOrganization?.viewerRole && activeOrganization.viewerRole !== "member")
    ) {
      return description
    }
    return `${productLabel.singular} ${roleLabel.singular}`
  }
}
