import { AppsSidebarNavSectionsDragDrop } from "@/apps/sidebar-item/AppsSidebarDragDropProvider"
import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import { AppLevelProvider } from "@/apps/util/appLevelContext"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoSideBarSkeleton } from "@/organization/common/sidebar/CommunitySideBar"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import CreateNavSectionButton from "@/organization/common/sidebar/nav-section/CreateNavSectionButton"
import NavSection from "@/organization/common/sidebar/nav-section/NavSection"
import HideExploreTabButton from "@/product/sidebar/HideExploreTabButton"
import ProductsSidebarDragDropProvider from "@/product/sidebar/ProductsSidebarDragDropProvider"
import ProductsSidebarHeader from "@/product/sidebar/ProductsSidebarHeader"
import ProductsSidebarList from "@/product/sidebar/ProductsSidebarList"
import { ProductsSidebarQuery } from "@/product/sidebar/__generated__/ProductsSidebarQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoSideBar, DiscoSideBarProps } from "@disco-ui"
import DiscoDividerButton from "@disco-ui/button/DiscoDividerButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { graphql, useSubscribeToInvalidationState } from "react-relay"

function ProductsSidebar(props: Partial<DiscoSideBarProps>) {
  const classes = useStyles()
  const isExploreSelected = location.pathname.startsWith(
    ROUTE_NAMES.COMMUNITY.EXPERIENCES.ROOT
  )
  const activeOrganization = useActiveOrganization()!
  const canReorder = activeOrganization.viewerPermissions.has("products.manage")
  const memberLabel = useLabel("organization_member")

  const [{ organization }, refetch] = Relay.useRefetchableQuery<ProductsSidebarQuery>(
    graphql`
      query ProductsSidebarQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            ...ProductsSidebar_NavSectionsFragment @relay(mask: false)
            ...ProductsSidebarList_OrganizationFragment
          }
        }
      }
    `,
    { id: activeOrganization.id }
  )

  const navSections = Relay.connectionToArray(organization?.productsNavSections)
  useSubscribeToInvalidationState(
    organization?.productsNavSections?.__id
      ? [organization.productsNavSections.__id]
      : [],
    () => refetch({ id: activeOrganization.id })
  )

  return (
    <AppLevelProvider>
      <ActiveAppModalProvider>
        <DiscoSideBar
          {...props}
          fullHeight
          data-testid={"ProductsSidebar"}
          header={<ProductsSidebarHeader />}
          items={
            <div className={classes.items}>
              {(!activeOrganization.isExploreProductTabHidden || canReorder) && (
                <div className={classes.links}>
                  <CommunitySidebarItem
                    testid={"ProductsSidebar.Experiences"}
                    name={"Explore"}
                    leftIcon={<DiscoIcon icon={"map"} active={isExploreSelected} />}
                    to={ROUTE_NAMES.COMMUNITY.EXPERIENCES.UPCOMING}
                    rightContent={
                      activeOrganization.isExploreProductTabHidden && (
                        <DiscoIcon icon={"eye-off"} className={classes.inactiveIcon} />
                      )
                    }
                    selected={isExploreSelected}
                    overflowItems={
                      canReorder && [
                        <HideExploreTabButton key={"isExploreTabHidden"}>
                          {(hideExploreTabButtonProps) => (
                            <DiscoDropdownItem
                              {...hideExploreTabButtonProps}
                              testid={`ProductsSidebar.Experiences.more-actions.${
                                activeOrganization.isExploreProductTabHidden
                                  ? "showExplore"
                                  : "hideExplore"
                              }`}
                              icon={
                                activeOrganization.isExploreProductTabHidden
                                  ? "eye"
                                  : "eye-off"
                              }
                              title={
                                activeOrganization.isExploreProductTabHidden
                                  ? `Show For All ${memberLabel.plural}`
                                  : `Hide For All ${memberLabel.plural}`
                              }
                            />
                          )}
                        </HideExploreTabButton>,
                      ]
                    }
                  />
                </div>
              )}
              <ProductsSidebarDragDropProvider>
                <ProductsSidebarList
                  testid={"ProductsSidebar.top-level-products"}
                  organizationKey={organization}
                />

                <AppsSidebarNavSectionsDragDrop
                  navSections={navSections}
                  disabled={!canReorder}
                >
                  {(section, dragHandleProps, isDragging, i) => (
                    <NavSection
                      navSectionKey={section}
                      index={i}
                      isDragging={isDragging}
                      dragHandleProps={dragHandleProps}
                    />
                  )}
                </AppsSidebarNavSectionsDragDrop>
              </ProductsSidebarDragDropProvider>

              {canReorder && (
                <CreateNavSectionButton
                  navSectionIndex={navSections.length}
                  kind={"products"}
                >
                  {(btnProps) => (
                    <DiscoDividerButton
                      testid={btnProps.testid}
                      tooltip={"Add Section"}
                      marginTop={0}
                      marginBottom={0}
                      marginLeft={0}
                      marginRight={0}
                      showOnHover
                      borderWidth={"1px"}
                      onClick={btnProps.onClick}
                    />
                  )}
                </CreateNavSectionButton>
              )}
            </div>
          }
        />
      </ActiveAppModalProvider>
    </AppLevelProvider>
  )
}

const useStyles = makeUseStyles((theme) => ({
  items: {
    paddingBottom: theme.spacing(2),
  },
  links: {
    padding: theme.spacing(2, 2, 0),
  },
  inactiveIcon: {
    // Increase specificity to override icon color when not selected
    ".DiscoSideBarItem__right-content svg&": {
      color: theme.palette.groovy.neutral[500],
    },
    opacity: 0.5,
  },
}))

function ProductsSidebarSkeleton() {
  const sidebarProduct = useLabel("sidebar_product")
  return <DiscoSideBarSkeleton title={sidebarProduct.singular} icon={"book"} />
}

export default Relay.withSkeleton({
  component: ProductsSidebar,
  skeleton: ProductsSidebarSkeleton,
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ProductsSidebar_NavSectionsFragment on Organization {
    id
    productsNavSections: navSections(kind: products, first: null)
      @connection(key: "ProductsSidebar__productsNavSections") {
      __id
      edges {
        node {
          id
          ...NavSectionFragment
        }
      }
    }
  }
`
