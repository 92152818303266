import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { truncateDecimalPart } from "@utils/number/numberUtils"
import classNames from "classnames"
import React from "react"

interface Props {
  progress: number
  bufferProgress: number
  variant: "curriculum" | "event"
  total: number
  color?: string
  bufferColor?: string
}

const MemberProgressBarLegendTooltip: React.FC<Props> = ({
  progress,
  bufferProgress,
  variant,
  total,
  color,
  bufferColor,
}) => {
  const classes = useStyles({ color, bufferColor })
  return (
    <div className={classes.container}>
      <div className={classes.legend}>
        <div className={classNames(classes.block, classes.color)} />
        <DiscoText color={"common.white"}>{`${truncateDecimalPart(0)(progress)}% ${
          variant === "curriculum" ? "completed" : "attended"
        }`}</DiscoText>
      </div>

      <div className={classes.legend}>
        <div className={classNames(classes.block, classes.bufferColor)} />
        <DiscoText color={"common.white"}>{`${truncateDecimalPart(0)(bufferProgress)}% ${
          variant === "curriculum" ? "available" : "past"
        }`}</DiscoText>
      </div>

      <div className={classes.legend}>
        <div className={classes.block} />
        <DiscoText color={"common.white"}>{`${truncateDecimalPart(0)(
          total ? 100 - bufferProgress : 0
        )}% ${variant === "curriculum" ? "unreleased" : "upcoming"}`}</DiscoText>
      </div>
    </div>
  )
}

interface StyleProps {
  color?: string
  bufferColor?: string
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    padding: theme.spacing(0.5),
  },
  legend: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(0.25, 0),
  },
  block: {
    borderRadius: theme.measure.borderRadius.default,
    width: "12px",
    height: "12px",
    backgroundColor: theme.palette.groovy.grey[100],
  },
  color: ({ color }: StyleProps) => ({
    backgroundColor: color ?? theme.palette.groovy.green[400],
  }),
  bufferColor: ({ bufferColor }: StyleProps) => ({
    backgroundColor: bufferColor ?? theme.palette.groovy.green[100],
  }),
}))
export default MemberProgressBarLegendTooltip
