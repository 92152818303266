import { useLabel } from "@/core/context/LabelsContext"
import StatusIcon from "@/core/ui/disco/icons/color-icons/status.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MessageProductMembersButton from "@/organization/people/actions/message/MessageProductMembersButton"
import AddMembersToGroupsButton from "@/product/common/member-group/button/AddMembersToGroupsButton"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoButton, DiscoIcon, DiscoIconButton, DiscoTooltip } from "@disco-ui"
import DiscoFloatingBar from "@disco-ui/floating-bar/DiscoFloatingBar"
import { useIsMobile } from "@utils/hook/screenSizeHooks"

interface ProductMembersListFloatingBar {
  memberships?: { id: GlobalID; memberId: GlobalID }[]
  allSelected?: {
    isAllSelected: boolean
    totalCount: number
  }
  onClose: () => void
  selectedGroupFilter?: "unassigned" | GlobalID
  hideGroupsButton?: boolean
}

function ProductMembersListFloatingBar({
  memberships,
  allSelected,
  selectedGroupFilter,
  onClose,
  hideGroupsButton = false,
}: ProductMembersListFloatingBar) {
  const classes = useStyles()
  const isMobile = useIsMobile()

  const membersLabel = useLabel("admin_member")
  const membersCount = getMembersSelectedCount()

  return (
    <DiscoFloatingBar
      belowModals
      open={membersCount > 0}
      onClose={onClose}
      icon={<StatusIcon width={24} height={24} />}
      contentClasses={{
        innerContent: classes.innerContent,
        contentButtons: classes.contentButtons,
      }}
      content={`${membersCount} ${
        membersCount === 1 ? membersLabel.singular : membersLabel.plural
      } selected`}
      buttons={
        <>
          {/** Can't add to groups when selecting all */}
          {!hideGroupsButton && (
            <AddMembersToGroupsButton
              memberships={memberships || []}
              disabled={allSelected?.isAllSelected}
              handleCloseFloatingBar={onClose}
            >
              {(props) => (
                <DiscoTooltip
                  content={
                    allSelected?.isAllSelected
                      ? `You can't add all ${membersLabel.plural} to a group. Please select fewer ${membersLabel.plural}.`
                      : isMobile
                      ? "Add to group"
                      : ""
                  }
                >
                  <div>
                    {isMobile ? (
                      <DiscoIconButton {...props} className={classes.iconButton}>
                        <DiscoIcon icon={"widget-add"} />
                      </DiscoIconButton>
                    ) : (
                      <DiscoButton
                        data-testid={"AddMembersToGroupButton"}
                        color={"grey"}
                        {...props}
                      >
                        {"Add to Group"}
                      </DiscoButton>
                    )}
                  </div>
                </DiscoTooltip>
              )}
            </AddMembersToGroupsButton>
          )}

          <MessageProductMembersButton
            memberships={memberships}
            isAllSelected={allSelected?.isAllSelected}
            onMessage={onClose}
            selectedGroupFilter={selectedGroupFilter}
            className={classes.sendEmailButton}
          >
            {(buttonProps) => (
              <DiscoButton {...buttonProps}>
                {`${isMobile ? "" : "Compose "}Email`}
              </DiscoButton>
            )}
          </MessageProductMembersButton>
        </>
      }
    />
  )

  function getMembersSelectedCount() {
    if (allSelected?.isAllSelected) {
      return allSelected.totalCount
    } else if (memberships?.length) {
      return memberships.length
    }
    return 0
  }
}

const useStyles = makeUseStyles((theme) => ({
  innerContent: {
    [theme.breakpoints.down("md")]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down("xs")]: {
      gap: 0,
    },
    alignItems: "center",
  },
  contentButtons: {
    alignItems: "center",
  },
  iconButton: {
    width: "unset",
  },
  sendEmailButton: {
    width: "155px",
    color: theme.palette.common.white,
    padding: theme.spacing(1.125, 1.5),
    marginRight: theme.spacing(1),
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      width: "115px",
      marginRight: 0,
    },
  },
}))

export default ProductMembersListFloatingBar
