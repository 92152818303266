import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"

function useIsAdminViewingAsMember(): boolean {
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  return Boolean(
    activeProduct?.isAdminViewingAsMember || activeOrganization?.isAdminViewingAsMember
  )
}
export default useIsAdminViewingAsMember
