/**
 * @generated SignedSource<<4ceaeff9c5f0f191b6128ed4e30f8add>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductsSidebarList_NavSectionFragment$data = {
  readonly id: string;
  readonly products: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly status: ProductStatus;
        readonly " $fragmentSpreads": FragmentRefs<"MyExperiencesListItemFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "ProductsSidebarList_NavSectionFragment";
};
export type ProductsSidebarList_NavSectionFragment$key = {
  readonly " $data"?: ProductsSidebarList_NavSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductsSidebarList_NavSectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "products"
        ]
      }
    ]
  },
  "name": "ProductsSidebarList_NavSectionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "products",
      "args": null,
      "concreteType": "ProductNodeConnection",
      "kind": "LinkedField",
      "name": "__ProductsSidebarList_NavSectionFragment__products_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MyExperiencesListItemFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavSection",
  "abstractKey": null
};
})();

(node as any).hash = "a7dd1148170ae294e1f813ac3e9c3621";

export default node;
