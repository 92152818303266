import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useIsWaitingRoomEnabledActiveProductFragment$key } from "@/product/util/hook/__generated__/useIsWaitingRoomEnabledActiveProductFragment.graphql"
import ProductUtils from "@/product/util/productUtils"
import usePermissions from "@utils/hook/usePermissions"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface UseIsWaitingRoomEnabledProps {
  productKey?: useIsWaitingRoomEnabledActiveProductFragment$key | null
}

function useIsWaitingRoomEnabled(props?: UseIsWaitingRoomEnabledProps) {
  const activeProduct = useActiveProduct()

  const product = useFragment<useIsWaitingRoomEnabledActiveProductFragment$key>(
    graphql`
      fragment useIsWaitingRoomEnabledActiveProductFragment on Product {
        waitingRoomEndsAt
        startDate
        ...usePermissionsFragment
      }
    `,
    props?.productKey || activeProduct
  )

  const currentWaitingRoomEndsAt = product?.waitingRoomEndsAt
    ? new Date(product?.waitingRoomEndsAt)
    : null

  const hasActiveWaitingRoom = product && ProductUtils.hasActiveWaitingRoom(product)

  const permissions = usePermissions(product)
  const isWaitingRoomEnabled = !permissions.has("content.manage") && hasActiveWaitingRoom

  return {
    isWaitingRoomEnabled,
    waitingRoomEndsAt: currentWaitingRoomEndsAt || product?.startDate,
    hasActiveWaitingRoom,
  }
}

export default useIsWaitingRoomEnabled
