import CreateMemberGroupButton from "@/product/common/member-group/common/create/button/CreateMemberGroupButton"
import CourseGroupsList, {
  CourseGroupsListSkeleton,
} from "@/product/course/settings/groups-settings/list/CourseGroupsList"
import { ExperienceSettingsGroupsTabFragment$key } from "@/product/settings/__generated__/ExperienceSettingsGroupsTabFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButtonSkeleton, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  experienceKey: ExperienceSettingsGroupsTabFragment$key
}

function ExperienceSettingsGroupsTab({ experienceKey }: Props) {
  const classes = useStyles()

  const product = useFragment<ExperienceSettingsGroupsTabFragment$key>(
    graphql`
      fragment ExperienceSettingsGroupsTabFragment on Product {
        id
        defaultMemberGroup {
          ...CreateMemberGroupButtonFragment
        }
        ...CourseGroupsListFragment
      }
    `,
    experienceKey || null
  )

  if (!product) return null

  return (
    <>
      {/* Groups List */}
      <CourseGroupsList
        productKey={product}
        classes={{ itemContainer: classes.listItem }}
        hideEmptyState
      />

      {/* Add Group Button */}
      <CreateMemberGroupButton
        testid={"ExperienceSettingsGroupsTab.add-group-button"}
        isSubGroup
        groupKey={product.defaultMemberGroup}
        width={"100%"}
        className={classes.addGroupButton}
        color={"grey"}
        variant={"dashed"}
        leftIcon={"add-circle"}
      >
        <DiscoText variant={"body-sm-600"} marginLeft={1}>
          {"Add New Sub-Group"}
        </DiscoText>
      </CreateMemberGroupButton>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  listItem: {
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  addGroupButton: {
    width: "100%",
  },
}))

export const ExperienceSettingsGroupsTabSkeleton = () => {
  return (
    <>
      <CourseGroupsListSkeleton />
      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperienceSettingsGroupsTab),
  skeleton: ExperienceSettingsGroupsTabSkeleton,
})
