import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import ProductAddAppDropdownContent from "@/product/sidebar/ProductAddAppDropdownContent"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoDropdown, DiscoDropdownProps } from "@disco-ui"

type Props = {
  children: DiscoDropdownProps["menuButton"]
  productId: GlobalID
}

export default function ProductAddAppButton({ children, productId }: Props) {
  return (
    <DiscoDropdown
      menuButton={children}
      horizontal={"center"}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      // The dropdown content runs a lazy load query, so don't mount it until opened
      mountChildrenOnOpen
    >
      <ActiveAppModalProvider>
        <ProductAddAppDropdownContent productId={productId} />
      </ActiveAppModalProvider>
    </DiscoDropdown>
  )
}
