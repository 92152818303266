import {
  OrganizationRole,
  ProductRole,
} from "@/admin/members/roles/__generated__/RoleAvatarStackQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { LabelData, useLabel, useLabels } from "@/core/context/LabelsContext"
import { usePermissionsFragment$key } from "@utils/hook/__generated__/usePermissionsFragment.graphql"
import usePermissions from "@utils/hook/usePermissions"

/**
 * Role label hooks
 */

export function useOrganizationRoleLabel(role: OrganizationRole): LabelData {
  const labels = useOrganizationRoleLabels()
  return labels[role]
}

export function useOrganizationRoleLabels(): Record<OrganizationRole, LabelData> {
  const labels = useLabels()
  return {
    owner: labels.organization_owner,
    admin: labels.organization_admin,
    member: labels.organization_member,
    "%future added value": labels.organization_member,
  }
}

export function useProductRoleLabel(role: ProductRole): LabelData {
  const labels = useProductRoleLabels()
  return labels[role]
}

export function useProductRoleLabels(): Record<ProductRole, LabelData> {
  const labels = useLabels()
  return {
    manager: labels.product_admin,
    instructor: labels.product_instructor,
    member: labels.product_member,
    "%future added value": labels.product_member,
  }
}

/**
 * Labels used in the admin management page & drawer
 */

const ADMIN_ORGANIZATION_ROLE_LABELS: Record<
  string,
  { singular: string; plural: string }
> = {
  owner: { singular: "Owner", plural: "Owners" },
  admin: { singular: "Admin", plural: "Admins" },
  member: { singular: "Member", plural: "Members" },
}

const ADMIN_PRODUCT_ROLE_LABELS: Record<string, { singular: string; plural: string }> = {
  manager: { singular: "Manager", plural: "Managers" },
  instructor: { singular: "Instructor", plural: "Instructors" },
  member: { singular: "Member", plural: "Members" },
}

export function useRoleAdminLabel(
  organizationRole?: OrganizationRole | null,
  productRole?: ProductRole | null
) {
  if (!organizationRole && !productRole)
    throw new Error("Must provide one of organizationRole or productRole")
  return organizationRole
    ? ADMIN_ORGANIZATION_ROLE_LABELS[organizationRole]
    : ADMIN_PRODUCT_ROLE_LABELS[productRole!]
}

/**
 * Role permission helper hooks
 */

export function useCanManageOrganizationRole(role?: OrganizationRole | null): boolean {
  const permissions = usePermissions(useActiveOrganization())
  if (!role) return false
  switch (role) {
    case "owner":
      return permissions.has("organization_owners.manage")
    case "admin":
      return permissions.has("organization_admins.manage")
    case "member":
      return permissions.has("members.manage")
  }
  throw new Error(`Unhandled organization role "${role}"`)
}

export function useCanManageProductRole(
  productKey: usePermissionsFragment$key,
  role?: ProductRole | null
): boolean {
  const permissions = usePermissions(productKey)
  if (!role) return false
  switch (role) {
    case "manager":
      return permissions.has("product_managers.manage")
    case "instructor":
      return permissions.has("product_instructors.manage")
    case "member":
      return permissions.has("members.manage")
  }
  throw new Error(`Unhandled product role "${role}"`)
}

export const ROLE_HIERARCHY: Record<OrganizationRole | ProductRole, number> = {
  owner: 4,
  admin: 3,
  manager: 2,
  instructor: 1,
  member: 0,
  "%future added value": -1,
}

/**
 * Role descriptions shown when hovering the role tags and other places
 */
export function useOrganizationRoleDescription(
  role?: OrganizationRole | null,
  adminLabels = false
): string {
  const productLabel = useLabel("admin_experience")
  const customRoleLabel = useOrganizationRoleLabel(role || "member")
  const roleAdminLabel = useRoleAdminLabel(role || "member")
  const roleLabel = adminLabels ? roleAdminLabel : customRoleLabel

  switch (role) {
    case "owner":
      return `${roleLabel.plural} can change billing information and manage all aspects of this community.`
    case "admin":
      return `${roleLabel.plural} can manage all ${productLabel.plural}, but cannot change Stripe or billing information.`
    case "member":
      return `${roleLabel.plural} can participate in your community and join ${productLabel.plural}.`
  }
  return ""
}

export function useProductRoleDescription(
  role?: ProductRole | null,
  adminLabels = false
): string {
  const productLabel = useLabel("admin_experience")
  const customRoleLabel = useProductRoleLabel(role || "member")
  const roleAdminLabel = useRoleAdminLabel(null, role || "member")
  const roleLabel = adminLabels ? roleAdminLabel : customRoleLabel

  switch (role) {
    case "manager":
      return `${roleLabel.plural} can adjust all aspects of a ${productLabel.singular}, including pricing.`
    case "instructor":
      return `${roleLabel.plural} can add/remove content and schedule events within a ${productLabel.singular}.`
    case "member":
      return `${roleLabel.plural} can participate in a ${productLabel.singular}.`
  }
  return ""
}
