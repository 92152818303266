import {
  postWebViewMessage,
  WebViewMessageType,
} from "@/apps/util/hooks/useWebViewMessage"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import useIsWebView from "@/product/util/hook/useIsWebView"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { useEffect } from "react"

const RegistrationDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "registration-drawer-content" */ "@/product/register/RegistrationDrawerContent"
    )
)

function RegistrationDrawer() {
  const drawer = useGlobalDrawer("registration")
  const modal = useInitUnsavedChangesModalContext()
  const classes = useStyles()
  const isWebView = useIsWebView()
  const isDrawerOpen = Boolean(
    drawer.params.drawerRegistrationExperienceId ||
      drawer.params.drawerRegistrationOccurrenceId
  )

  // Send a message to the webview if the user is on the registration drawer
  useEffect(() => {
    if (!isDrawerOpen) return
    postWebViewMessage({
      type: WebViewMessageType.on_registration,
      frontendPath: window.location.href,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Do not show the registration drawer for products in a webview
  if (drawer.params.drawerRegistrationExperienceId && isWebView) return null

  return (
    // If leaving with unsaved changes, display the unsaved changes modal
    <UnsavedChangesModalProvider {...modal}>
      <DiscoDrawer
        open={drawer.isOpen}
        onClose={handleCloseDrawer}
        containerClasses={{ drawerContainer: classes.drawer }}
      >
        {/* Content */}
        {isDrawerOpen && <RegistrationDrawerContent />}
      </DiscoDrawer>
    </UnsavedChangesModalProvider>
  )

  function handleCloseDrawer() {
    // Check that there are no unsaved changes before closing
    modal.handleLeave({
      onLeave: drawer.close,
    })
  }
}

const useStyles = makeUseStyles({
  drawer: {
    padding: 0,
  },
})

export default RegistrationDrawer
