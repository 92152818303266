import { invalidateRoleAvatarStackProductRoles } from "@/admin/members/roles/RoleAvatarStack"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { UpdateProductRoleButtonFragment$key } from "@/role/__generated__/UpdateProductRoleButtonFragment.graphql"
import {
  ProductRole,
  UpdateProductRoleButtonMutation,
} from "@/role/__generated__/UpdateProductRoleButtonMutation.graphql"
import { ROLE_HIERARCHY, useProductRoleLabel } from "@/role/roleUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useEffect, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type UpdateProductRoleButtonProps = {
  children: OverridableDiscoButtonProps["children"]
  productMembershipKey: UpdateProductRoleButtonFragment$key
  updateRole: ProductRole
}

export default function UpdateProductRoleButton(props: UpdateProductRoleButtonProps) {
  const { children, productMembershipKey, updateRole } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const activeOrganization = useActiveOrganization()!

  const productMembership = useFragment<UpdateProductRoleButtonFragment$key>(
    graphql`
      fragment UpdateProductRoleButtonFragment on ProductMembership {
        id
        role
        member {
          fullName
        }
      }
    `,
    productMembershipKey
  )

  const form = useFormStore<UpdateProductRoleButtonMutation>(
    graphql`
      mutation UpdateProductRoleButtonMutation($input: UpdateProductMembershipInput!) {
        response: updateProductMembership(input: $input) {
          productMembership {
            id
            role
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: productMembership.id,
      role: updateRole,
    }
  )

  useEffect(() => {
    form.state.role = updateRole
  }, [updateRole, form])

  const currentRoleLabel = useProductRoleLabel(productMembership.role)
  const updateRoleLabel = useProductRoleLabel(updateRole)
  const isDemotion = ROLE_HIERARCHY[updateRole] < ROLE_HIERARCHY[productMembership.role]

  return (
    <>
      <OverridableDiscoButton onClick={() => setIsModalOpen(true)} {...props}>
        {children}
      </OverridableDiscoButton>
      <DiscoWarningModal
        testid={"UpdateProductRoleButton.modal"}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        variant={isDemotion ? "error" : "primary"}
        title={`Change role to ${updateRoleLabel.singular}?`}
        modalContentLabel={"Update role dialog"}
        description={`Are you sure you want to ${isDemotion ? "demote" : "promote"} ${
          productMembership.member.fullName
        } from ${currentRoleLabel.singular} to ${updateRoleLabel.singular}?`}
        confirmationButtonProps={{
          onClick: handleSubmit,
          shouldDisplaySpinner: form.isSubmitting,
          children: `Yes, change to ${updateRoleLabel.singular}`,
        }}
      />
    </>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(form.state, {
      updater: (store) => {
        invalidateRoleAvatarStackProductRoles(store, activeOrganization.id)
      },
    })
    if (!didSave) return

    displaySuccessToast({
      message: `Successfully changed to ${updateRoleLabel.singular}`,
      testid: "UpdateProductRoleButton.success-toast",
    })
    setIsModalOpen(false)
  }
}
