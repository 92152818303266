import CheckoutFormInvitationSection from "@/product/checkout/form/CheckoutFormInvitationSection"
import CheckoutFormRegistrationErrorStep from "@/product/checkout/form/registration/CheckoutFormRegistrationErrorStep"
import useCheckoutURLForAnalytics from "@/product/checkout/store/useCheckoutURLForAnalytics"
import useStartCheckout from "@/product/checkout/store/useStartCheckout"
import ProductRegistrationApplyStep from "@/product/register/steps/ProductRegistrationApplyStep"
import ProductRegistrationPaymentStep from "@/product/register/steps/ProductRegistrationPaymentStep"
import ProductRegistrationStartStep, {
  ProductRegistrationStartStepSkeleton,
} from "@/product/register/steps/ProductRegistrationStartStep"
import ProductRegistrationSuccessStep from "@/product/register/steps/ProductRegistrationSuccessStep"
import Relay from "@/relay/relayUtils"
import StripeUtils from "@/stripe/util/StripeUtils"
import { Elements } from "@stripe/react-stripe-js"
import { TestIDProps } from "@utils/typeUtils"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import ProductRegistrationVerifyEmailStep from "./ProductRegistrationVerifyEmailStep"

type Props = TestIDProps

function ProductRegistrationForm({ testid = "ProductRegistration" }: Props) {
  const { store, product } = useStartCheckout()
  const stripePromise = StripeUtils.loadStripe()

  useCheckoutURLForAnalytics(store)

  if (!product) return <ProductRegistrationFormSkeleton />

  return (
    <div data-testid={"ProductRegistration.container"}>
      <div data-testid={`${testid}Form.form`}>
        <Elements stripe={stripePromise}>
          <div
            data-testid={`${testid}Form.checkout-id`}
            data-checkout-id={store.checkout?.id}
          >
            {renderInvitationSection()}

            {store.startCheckoutError ? (
              <CheckoutFormRegistrationErrorStep
                errorMessage={store.startCheckoutError.message}
                title={store.checkout?.hasApplication ? "Application" : "Registration"}
                authUserLabel={"Register as:"}
                productKey={toJS(store.product!)}
              />
            ) : (
              <>{renderRegistrationStep()}</>
            )}
          </div>
        </Elements>
      </div>
    </div>
  )

  function renderRegistrationStep() {
    switch (store.currentStep) {
      case "registration":
        return <ProductRegistrationStartStep store={store} />
      case "verify_email":
        return <ProductRegistrationVerifyEmailStep store={store} testid={testid} />
      case "apply":
        return <ProductRegistrationApplyStep store={store} />
      case "payment":
        return <ProductRegistrationPaymentStep store={store} />
      case "success":
        return <ProductRegistrationSuccessStep store={store} testid={testid} />
      default:
        return <ProductRegistrationStartStep store={store} />
    }
  }

  function renderInvitationSection() {
    if (store.currentStep === "success") return null
    if (store.checkout?.invitation) return <CheckoutFormInvitationSection store={store} />
    return null
  }
}

export const ProductRegistrationFormSkeleton: React.FC = () => {
  return <ProductRegistrationStartStepSkeleton />
}

export default Relay.withSkeleton<Props>({
  component: observer(ProductRegistrationForm),
  skeleton: ProductRegistrationFormSkeleton,
})
