import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ProductsSidebarAddButton from "@/product/sidebar/ProductsSidebarAddButton"
import { DiscoIcon, DiscoIconButton } from "@disco-ui"
import DiscoSidebarHeader from "@disco-ui/sidebar/DiscoSidebarHeader"

export default function ProductsSidebarHeader() {
  const activeOrganization = useActiveOrganization()!
  const canAdd = activeOrganization.viewerPermissions.has("products.create")
  const productLabel = useLabel("sidebar_product")

  return (
    <DiscoSidebarHeader
      title={productLabel.singular}
      icon={"book"}
      actions={
        canAdd && (
          <ProductsSidebarAddButton>
            {({ onClick }) => (
              <DiscoIconButton
                size={"small"}
                onClick={(e) => {
                  // Keeps sidebar from closing on mobile which unmounts add modal
                  e.stopPropagation()
                  onClick(e)
                }}
                testid={"ProductsSidebarHeader.add-button"}
              >
                <DiscoIcon icon={"add"} />
              </DiscoIconButton>
            )}
          </ProductsSidebarAddButton>
        )
      }
    />
  )
}
