import CreateExperienceButton from "@/admin/experiences/create/CreateExperienceButton"
import { useLabel } from "@/core/context/LabelsContext"
import CreateNavSectionButton from "@/organization/common/sidebar/nav-section/CreateNavSectionButton"
import { DiscoDropdown, DiscoDropdownProps } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"

type Props = {
  children: DiscoDropdownProps["menuButton"]
}

export default function ProductsSidebarAddButton({ children }: Props) {
  const productLabel = useLabel("admin_experience")

  return (
    <DiscoDropdown
      menuButton={children}
      horizontal={"right"}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <CreateExperienceButton>
        {({ onClick }) => (
          <DiscoDropdownItem
            testid={"ProductsSidebarAddButton.product"}
            onClick={onClick}
            title={`Add ${productLabel.singular}`}
          />
        )}
      </CreateExperienceButton>
      <CreateNavSectionButton navSectionIndex={0} kind={"products"}>
        {({ onClick }) => (
          <DiscoDropdownItem
            testid={"ProductsSidebarAddButton.nav-section"}
            onClick={onClick}
            title={"Add Section"}
          />
        )}
      </CreateNavSectionButton>
    </DiscoDropdown>
  )
}
