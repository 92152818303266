import { useAuthUser } from "@/core/context/AuthUserContext"
import CheckoutFormProfileAuthUserSection from "@/product/checkout/form/registration/CheckoutFormProfileAuthUserSection"
import { GiftProductFormStore } from "@/product/gift/GiftProductForm"
import { DiscoFormLabel, DiscoTextField } from "@disco-ui"
import Grid from "@material-ui/core/Grid"
import { observer } from "mobx-react-lite"

interface Props {
  form: GiftProductFormStore
}

const GiftProductFormGifterSection = observer<Props>((props) => {
  const { form } = props
  const { gifter } = form.state

  const { authUser } = useAuthUser()

  if (!gifter.isNewUser && authUser) {
    return <CheckoutFormProfileAuthUserSection label={"Send gift as:"} />
  }

  return (
    <>
      <DiscoFormLabel>{"Your Details"}</DiscoFormLabel>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DiscoTextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            label={"First Name"}
            name={"first-name"}
            value={gifter.firstName || ""}
            onChange={(e) => (gifter.firstName = e.target.value)}
            data-testid={"GiftProductFormGifterSection.first-name"}
          />
        </Grid>
        <Grid item xs={12}>
          <DiscoTextField
            label={"Last Name"}
            name={"last-name"}
            value={gifter.lastName || ""}
            onChange={(e) => (gifter.lastName = e.target.value)}
            data-testid={"GiftProductFormGifterSection.last-name"}
          />
        </Grid>
        <Grid item xs={12}>
          <DiscoTextField
            label={"Email"}
            name={"email"}
            type={"email"}
            value={gifter.email || ""}
            onChange={(e) => (gifter.email = e.target.value)}
            data-testid={"GiftProductFormGifterSection.email"}
          />
        </Grid>
        <Grid item xs={12}>
          <DiscoTextField
            label={"Confirm your email"}
            name={"confirm-email"}
            type={"email"}
            value={gifter.confirmEmail || ""}
            onChange={(e) => (gifter.confirmEmail = e.target.value)}
            error={Boolean(
              gifter.email && gifter.confirmEmail && gifter.email !== gifter.confirmEmail
            )}
            data-testid={"GiftProductFormGifterSection.email-confirm"}
          />
        </Grid>
      </Grid>
    </>
  )
})

export default GiftProductFormGifterSection
