import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState, useStopViewAsMember } from "@/core/route/util/routeUtils"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 * Whether or not the user is viewing as member should be determined by checking this
 * variable on the global window instead of checking the query params, since the viewAs
 * param can be momentarily removed during a component's first render when it sends a
 * query. The query param is only used to maintain viewing as member upon refresh.
 */
type ViewAsRole = "member"
declare global {
  interface Window {
    discoViewAs?: ViewAsRole
  }
}
export function isViewingAsMember() {
  return window.discoViewAs === "member"
}

const AUTH_ROUTES = new Set<string>(Object.values(ROUTE_NAMES.AUTHENTICATION))

/**
 * Initialize hook at root app level to append `viewAs` to query params if it was removed
 * while still viewing as member
 */
function useInitViewAsMember() {
  const { pathname } = useLocation<LocationState>()
  const isWebView = useIsWebView()
  const stopViewAs = useStopViewAsMember()
  const [params, setParams] = useQueryParamState<{ viewAs?: ViewAsRole }>()

  // Set the global var immediately for use in GraphQL queries if necessary
  const isExcludedPath = AUTH_ROUTES.has(pathname)
  if (!isExcludedPath && params.viewAs && params.viewAs !== window.discoViewAs) {
    window.discoViewAs = params.viewAs
  }

  useEffect(() => {
    // If navigating to excluded path, do not append viewAs param, and close/reload the
    // window if we are currently viewing as member
    if (isExcludedPath) {
      if (window.discoViewAs) stopViewAs()
      return
    }

    // If viewAs was on, or we are in a webview, persist param on navigation
    if (!params.viewAs && (window.discoViewAs || isWebView)) {
      setParams({ viewAs: window.discoViewAs || "member" }, "replace")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExcludedPath, stopViewAs, isWebView, params.viewAs])
}
export default useInitViewAsMember
