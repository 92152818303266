import makeUseStyles from "@assets/style/util/makeUseStyles"
import ExternalDiscoTextButton from "@components/buttons/ExternalDiscoTextButton"
import { DiscoTooltip, DiscoTooltipProps } from "@disco-ui"
import React from "react"

interface Props extends DiscoTooltipProps {
  learnMoreLink?: string
}

function ExperienceSettingsTooltip({ content, learnMoreLink, ...rest }: Props) {
  const classes = useStyles()

  return (
    <DiscoTooltip
      buttonProps={{
        classes: {
          root: classes.tooltip,
        },
      }}
      interactive
      content={
        <>
          {content}

          {learnMoreLink && (
            <ExternalDiscoTextButton
              to={learnMoreLink}
              title={"Learn More"}
              className={classes.learnMore}
              iconPosition={"right"}
            />
          )}
        </>
      }
      {...rest}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  tooltip: {
    display: "inline",
    "& svg": {
      color: theme.palette.text.disabled,
    },
  },
  learnMore: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    padding: theme.spacing(1, 0),
  },
}))

export default ExperienceSettingsTooltip
