import { LabelFormFieldSkeleton } from "@/admin/labels/LabelFormField"
import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import EditEmailTemplateDrawerButton from "@/product/course/settings/notifications-settings/email/EditEmailTemplateDrawerButton"
import EmailTemplate from "@/product/course/settings/notifications-settings/email/util/EmailTemplateTypes"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import { ExperienceSettingsNotificationsFormFieldsFragment$key } from "@/product/settings/__generated__/ExperienceSettingsNotificationsFormFieldsFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoInput, DiscoSwitch, DiscoTextButton } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { default as React } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  experienceKey: ExperienceSettingsNotificationsFormFieldsFragment$key
  form: FormStore<ExperienceSettingsFormState, ExperienceSettingsFormMutation>
}

function ExperienceSettingsNotificationsFormFields({ experienceKey, form }: Props) {
  const classes = useStyles()
  const membersLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")
  const assignmentLabel = useLabel("assignment")
  const curriculumLabel = useLabel("curriculum")
  const { connectedApps } = useConnectedProductApps()

  const product = useFragment<ExperienceSettingsNotificationsFormFieldsFragment$key>(
    graphql`
      fragment ExperienceSettingsNotificationsFormFieldsFragment on Product {
        id
        emailTemplates {
          edges {
            node {
              id
              kind
              ...EditEmailTemplateFormFragment
            }
          }
        }
      }
    `,
    experienceKey || null
  )

  const emailTemplates = Relay.connectionToArray(product.emailTemplates)
  const welcomeEmailTemplate = emailTemplates.find(
    (et) => et.kind === EmailTemplate.KIND.WELCOME
  )
  const earlyAccessBeginsEmailTemplate = emailTemplates.find(
    (et) => et.kind === EmailTemplate.KIND.WAITING_ROOM
  )
  const experienceStartsTomorrowTemplate = emailTemplates.find(
    (et) => et.kind === EmailTemplate.KIND.STARTS_TOMORROW
  )
  const assignmentDueReminderTemplate = emailTemplates.find(
    (et) => et.kind === EmailTemplate.KIND.ASSIGNMENT_DUE_REMINDER
  )

  return (
    <div>
      {/* New Comment & Replies*/}
      <DiscoFormControl
        title={"New Comments & Replies"}
        description={
          "Triggered when a new comment or reply is made on posts, assignments, lessons, etc"
        }
        error={Boolean(form.errorsByField.sendNewCommentEmail)}
        errorMessages={form.errorsByField.sendNewCommentEmail}
        variant={"two-column"}
        className={classes.form}
      >
        <DiscoSwitch
          testid={"ExperienceSettingsNotificationsFormFields.newCommentAndReplies.switch"}
          checked={Boolean(form.state.sendNewCommentEmail)}
          onChange={handleSendNewCommentEmailToggle}
          classes={{ root: classes.switch }}
        />
      </DiscoFormControl>

      {/* Welcome Message */}
      {welcomeEmailTemplate && (
        <DiscoFormControl
          title={"Welcome Message"}
          description={`Triggered when someone registers for your ${experienceLabel.singular}.`}
          error={Boolean(form.errorsByField.sendCourseRegistrationEmailToAttendees)}
          errorMessages={form.errorsByField.sendCourseRegistrationEmailToAttendees}
          variant={"two-column"}
          className={classes.form}
          tooltip={
            (form.state.isAutoJoined &&
              `This email can not be enabled when the ${experienceLabel.singular} is set to auto-join mode.`) ||
            undefined
          }
        >
          <div className={classes.editEmail}>
            <DiscoSwitch
              testid={"ExperienceSettingsNotificationsFormFields.welcomeMessage.switch"}
              checked={
                form.state.isAutoJoined
                  ? false
                  : Boolean(form.state.sendCourseRegistrationEmailToAttendees)
              }
              onChange={handleSendCourseRegistrationEmailToggle}
              classes={{ root: classes.switch }}
              disabled={form.state.isAutoJoined || false}
            />

            <EditEmailTemplateDrawerButton emailTemplateKey={welcomeEmailTemplate}>
              {({ onClick }) => (
                <DiscoTextButton
                  onClick={onClick}
                  data-testid={
                    "ExperienceSettingsNotificationsFormFields.edit-welcome-email-template-button"
                  }
                >
                  {"Edit Email"}
                </DiscoTextButton>
              )}
            </EditEmailTemplateDrawerButton>
          </div>
        </DiscoFormControl>
      )}

      {/* Early Access Begins Message */}
      {earlyAccessBeginsEmailTemplate && (
        <DiscoFormControl
          title={"Early Access Started"}
          description={"Triggered when early access begins."}
          variant={"two-column"}
          className={classes.form}
          tooltip={
            (!form.state.allowEarlyAccess &&
              `This email can only be enabled if 'Allow Early Access' is enabed under 'Availability' for ${experienceLabel.plural} with a fixed duration.`) ||
            undefined
          }
        >
          <div className={classes.editEmail}>
            <DiscoSwitch
              testid={"ExperienceSettingsNotificationsFormFields.earlyAccess.switch"}
              checked={Boolean(form.state.sendEarlyAccessStartedEmail)}
              onChange={handleSendEarlyAccessStartedEmailToggle}
              classes={{ root: classes.switch }}
              disabled={!form.state.allowEarlyAccess}
            />
            <EditEmailTemplateDrawerButton
              emailTemplateKey={earlyAccessBeginsEmailTemplate}
            >
              {({ onClick }) => (
                <DiscoTextButton
                  onClick={onClick}
                  data-testid={
                    "ExperienceSettingsNotificationsFormFields.edit-early-access-email-template-button"
                  }
                >
                  {"Edit Email"}
                </DiscoTextButton>
              )}
            </EditEmailTemplateDrawerButton>
          </div>
        </DiscoFormControl>
      )}

      {/* Experience Starts Tomorrow */}
      {experienceStartsTomorrowTemplate && form.state.startDate && (
        <DiscoFormControl
          title={`${experienceLabel.singular} Starts Tomorrow`}
          description={`Triggered 1 day before the start date - sends to all ${membersLabel.plural} at the time.`}
          error={Boolean(form.errorsByField.startDate)}
          errorMessages={form.errorsByField.startDate}
          variant={"two-column"}
          className={classes.form}
        >
          <div className={classes.editEmail}>
            <DiscoSwitch
              testid={"ExperienceSettingsNotificationsFormFields.courseReminder.switch"}
              checked={Boolean(form.state.sendCourseReminderEmail)}
              onChange={handleSendCourseReminderEmailToggle}
              classes={{ root: classes.switch }}
            />

            <EditEmailTemplateDrawerButton
              emailTemplateKey={experienceStartsTomorrowTemplate}
            >
              {({ onClick }) => (
                <DiscoTextButton
                  onClick={onClick}
                  data-testid={
                    "ExperienceSettingsNotificationsFormFields.edit-starts-tomorrow-email-template-button"
                  }
                >
                  {"Edit Email"}
                </DiscoTextButton>
              )}
            </EditEmailTemplateDrawerButton>
          </div>
        </DiscoFormControl>
      )}

      {/* Assignment Due Reminder */}
      {assignmentDueReminderTemplate && (
        <DiscoFormControl
          title={`${assignmentLabel.singular} Due Date Reminder`}
          description={"Triggered 24 hours before the due date."}
          variant={"two-column"}
          className={classes.form}
          tooltip={
            (!connectedApps.has("curriculum") &&
              `This email can only be enabled when the ${experienceLabel.singular} has the ${curriculumLabel.singular} App enabled.`) ||
            undefined
          }
        >
          <div className={classes.editEmail}>
            <DiscoSwitch
              testid={
                "ExperienceSettingsNotificationsFormFields.assignment-due-reminder.switch"
              }
              checked={
                connectedApps.has("curriculum") &&
                Boolean(form.state.sendAssignmentDueNotifications)
              }
              onChange={handleSendAssignmentDueNotificationsToggle}
              classes={{ root: classes.switch }}
              disabled={!connectedApps.has("curriculum")}
            />

            <EditEmailTemplateDrawerButton
              emailTemplateKey={assignmentDueReminderTemplate}
            >
              {({ onClick }) => (
                <DiscoTextButton
                  onClick={onClick}
                  data-testid={
                    "ExperienceSettingsNotificationsFormFields.edit-assignment-due-reminder-email-template-button"
                  }
                >
                  {"Edit Email"}
                </DiscoTextButton>
              )}
            </EditEmailTemplateDrawerButton>
          </div>
        </DiscoFormControl>
      )}

      <DiscoFormControl
        title={"Reply-to Email"}
        description={"The email that replies will be sent to."}
        error={Boolean(form.errorsByField.replyToEmailAddress)}
        errorMessages={form.errorsByField.replyToEmailAddress}
        variant={"two-column"}
        className={classes.form}
      >
        <DiscoInput
          data-testid={"ExperienceSettingsNotificationsFormFields.replyToEmail"}
          value={form.state.replyToEmailAddress}
          onChange={handleReplyToEmailAddressChange}
          fullWidth
        />
      </DiscoFormControl>
    </div>
  )

  function handleSendNewCommentEmailToggle() {
    form.state.sendNewCommentEmail = !form.state.sendNewCommentEmail
  }

  function handleSendCourseRegistrationEmailToggle() {
    form.state.sendCourseRegistrationEmailToAttendees =
      !form.state.sendCourseRegistrationEmailToAttendees
  }

  function handleSendEarlyAccessStartedEmailToggle() {
    form.state.sendEarlyAccessStartedEmail = !form.state.sendEarlyAccessStartedEmail
  }

  function handleSendCourseReminderEmailToggle() {
    form.state.sendCourseReminderEmail = !form.state.sendCourseReminderEmail
  }

  function handleSendAssignmentDueNotificationsToggle() {
    form.state.sendAssignmentDueNotifications = !form.state.sendAssignmentDueNotifications
  }

  function handleReplyToEmailAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.replyToEmailAddress = event.target.value
  }
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    display: "flex",
    alignItems: "center",
  },
  switch: {
    marginLeft: theme.spacing(-2),
  },
  editEmail: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
}))

export const ExperienceSettingsNotificationsFormFieldsSkeleton = () => {
  const classes = useStyles()
  const heights = [70, 70, 50]

  return (
    <div className={classes.skeletonContainer}>
      {heights.map((height) => (
        <LabelFormFieldSkeleton key={height} height={height} width={350} />
      ))}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperienceSettingsNotificationsFormFields),
  skeleton: ExperienceSettingsNotificationsFormFieldsSkeleton,
})
