import { OperationType, PageInfo } from "relay-runtime"

/** GlobalID encodes a Node's type + raw database ID in Base64 */
export type GlobalID = string

/** A Node is any object with an ID. */
export type RelayNode = {
  readonly id: GlobalID
}

/** A Connection is a list of nodes with pagination metadata */
export type Connection<T = RelayNode> = {
  readonly edges?: ReadonlyArray<Edge<T>>
  readonly totalCount?: number
  readonly pageInfo?: PageInfo
}

/** Extract the underlying node's type from a Connection */
export type NodeFromConnection<
  T extends Connection<N> | undefined | null,
  N = unknown
> = NonNullable<NonNullable<T>["edges"]>[0]["node"]

/** Edges are a single item in a connection */
export type Edge<T = RelayNode> = {
  readonly node: T
}

/** A GraphQL query that returns a top-level node with ID */
export type NodeQuery<TNode = RelayNode> = OperationType & {
  response: {
    readonly node: null | TNode
  }
}

/** Extract the type of the node() response from a GraphQL Query type */
export type NodeFromResponse<TQuery extends NodeQuery> = NonNullable<
  TQuery["response"]["node"]
>

/** Cursor encodes the index of an item in a Connection for pagination */
export type Cursor = GlobalID

/** Arguments accepted by any GraphQL Connection field */
export type ConnectionArguments = {
  first?: number | null
  last?: number | null
  before?: Cursor | null
  after?: Cursor | null
}

export interface ValidationError {
  readonly field: string
  readonly message: string
}

export function isValidationError(x: unknown): x is Pick<ValidationError, "message"> {
  return Boolean(typeof x === "object" && x?.hasOwnProperty("message"))
}
