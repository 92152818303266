import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import {
  TrackUserActivityInput,
  useTrackUserActivityMutation,
} from "@/reporting/__generated__/useTrackUserActivityMutation.graphql"
import { useCallback } from "react"
import { useMutation } from "react-relay"
import { graphql } from "relay-runtime"

/**
 * Track a user activity event
 */
function useTrackUserActivity() {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()

  const [commit] = useMutation<useTrackUserActivityMutation>(graphql`
    mutation useTrackUserActivityMutation($input: TrackUserActivityInput!) {
      trackUserActivity(input: $input) {
        errors {
          field
          message
        }
      }
    }
  `)

  return useCallback(
    (args: Pick<TrackUserActivityInput, "kind" | "entityId" | "metadata">) => {
      const { kind, entityId, metadata } = args

      commit({
        variables: {
          input: {
            organizationId: activeOrganization.id,
            productId: activeProduct?.id,
            kind,
            entityId,
            metadata,
          },
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export default useTrackUserActivity
