/**
 * @generated SignedSource<<0c0b4057b6c4d36752a1ff1b06539724>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type LabelKind = "product_member" | "product_instructor" | "product_admin" | "organization_member" | "organization_admin" | "organization_owner" | "experience" | "admin_experience" | "admin_member" | "curriculum" | "custom" | "lesson" | "assignment" | "quiz" | "sidebar_community" | "sidebar_product" | "pathway" | "%future added value";
export type OrganizationCurrency = "usd" | "cad" | "eur" | "aud" | "gbp" | "%future added value";
export type OrganizationVisibility = "public" | "private" | "%future added value";
export type ThemeKind = "light" | "dark" | "custom" | "%future added value";
export type UpdateOrganizationSettingsInput = {
  id: string;
  name?: string | null;
  slug?: string | null;
  description?: string | null;
  logo?: string | null;
  logoAssetId?: string | null;
  darkLogo?: string | null;
  darkLogoAssetId?: string | null;
  cover?: string | null;
  coverAssetId?: string | null;
  faviconUrl?: string | null;
  isDmEnabled?: boolean | null;
  isChannelsEnabled?: boolean | null;
  isProductsEnabled?: boolean | null;
  isExploreProductTabHidden?: boolean | null;
  isEventsEnabled?: boolean | null;
  showGlobalTabTitles?: boolean | null;
  billingContactEmail?: string | null;
  currency?: OrganizationCurrency | null;
  visibility?: OrganizationVisibility | null;
  tracking?: UpdateTrackingInput | null;
  experienceNavOrdering?: ReadonlyArray<string> | null;
  badge?: BadgeInput | null;
  theme?: OrganizationSettingsThemeInput | null;
  label?: OrganizationSettingsLabelInput | null;
};
export type UpdateTrackingInput = {
  googleAnalyticsId?: string | null;
  facebookPixelId?: string | null;
  googleTagManagerId?: string | null;
};
export type BadgeInput = {
  icon?: string | null;
  color?: string | null;
  emoji?: string | null;
  mediaUrl?: string | null;
  assetId?: string | null;
  kind: BadgeKind;
};
export type OrganizationSettingsThemeInput = {
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
  backgroundColor?: string | null;
  cardBackgroundColor?: string | null;
  navBackgroundColor?: string | null;
  kind?: ThemeKind | null;
};
export type OrganizationSettingsLabelInput = {
  labels: ReadonlyArray<UpdateLabelInput>;
};
export type UpdateLabelInput = {
  kind: LabelKind;
  singular: string;
  plural: string;
  description?: string | null;
};
export type HideExploreTabButtonMutation$variables = {
  input: UpdateOrganizationSettingsInput;
};
export type HideExploreTabButtonMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly isExploreProductTabHidden: boolean;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type HideExploreTabButtonMutation = {
  variables: HideExploreTabButtonMutation$variables;
  response: HideExploreTabButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateOrganizationSettingsMutationResponse",
    "kind": "LinkedField",
    "name": "updateOrganizationSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isExploreProductTabHidden",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HideExploreTabButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HideExploreTabButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5b34539134bd2794083575d3ec2c576d",
    "id": null,
    "metadata": {},
    "name": "HideExploreTabButtonMutation",
    "operationKind": "mutation",
    "text": "mutation HideExploreTabButtonMutation(\n  $input: UpdateOrganizationSettingsInput!\n) {\n  response: updateOrganizationSettings(input: $input) {\n    node {\n      id\n      isExploreProductTabHidden\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd25dcbb52952dbc4fae3557fda21992";

export default node;
