import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { HideExploreTabButtonMutation } from "@/product/sidebar/__generated__/HideExploreTabButtonMutation.graphql"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoText } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { graphql } from "relay-runtime"

type HideExploreTabButtonProps = {
  children: OverridableDiscoButtonChildren
}

export default function HideExploreTabButton(props: HideExploreTabButtonProps) {
  const { children } = props
  const { isOpen, onClose, onOpen } = useDisclosure()
  const activeOrganization = useActiveOrganization()!
  const labels = useLabels()

  const form = useFormStore<HideExploreTabButtonMutation>(
    graphql`
      mutation HideExploreTabButtonMutation($input: UpdateOrganizationSettingsInput!) {
        response: updateOrganizationSettings(input: $input) {
          node {
            id
            isExploreProductTabHidden
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      isExploreProductTabHidden: activeOrganization.isExploreProductTabHidden,
    }
  )
  return (
    <>
      <OverridableDiscoButton onClick={onOpen}>{children}</OverridableDiscoButton>

      {isOpen && (
        <DiscoWarningModal
          testid={"HideExploreTab"}
          isOpen={isOpen}
          onClose={onClose}
          variant={"warning"}
          title={renderTitle()}
          description={renderDescription()}
          confirmationButtonProps={{
            onClick: handleSubmit,
            shouldDisplaySpinner: form.isSubmitting,
            children: "Confirm",
          }}
          modalContentLabel={`Hide Explore Page`}
        />
      )}
    </>
  )

  function renderTitle() {
    return `Are you sure you want to ${
      activeOrganization.isExploreProductTabHidden ? "show" : "hide"
    } the Explore ${labels.admin_experience.plural} page for all ${
      labels.organization_member.plural
    }`
  }

  function renderDescription() {
    if (activeOrganization.isExploreProductTabHidden)
      return (
        <DiscoText>
          {`This is will show the Explore page for all ${labels.organization_member.plural}`}
        </DiscoText>
      )
    return (
      <DiscoText>
        {`This will hide the Explore page for all ${labels.organization_member.plural}. The Explore page is where ${labels.organization_member.plural} can find and register for new ${labels.admin_experience.plural}. This setting can have significant impact on ${labels.organization_member.plural} ability to join ${labels.admin_experience.plural}, when using this setting we recommend having Auto-Add turned on for your ${labels.admin_experience.plural}`}
      </DiscoText>
    )
  }

  async function handleSubmit() {
    const { didSave } = await form.submit({
      id: activeOrganization.id,
      isExploreProductTabHidden: !activeOrganization.isExploreProductTabHidden,
    })
    if (!didSave) return
    displaySuccessToast({
      message: "Explore Page Hidden",
      testid: "test",
    })
    onClose()
  }
}
