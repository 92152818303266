import { LabelFormFieldSkeleton } from "@/admin/labels/LabelFormField"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import QuestionFormField from "@components/question/create/form-field/QuestionFormField"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoInput,
  DiscoLinkInput,
  DiscoSwitch,
  DiscoText,
} from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { FormControl } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { v4 as uuidv4 } from "uuid"

interface Props extends TestIDProps {
  form: FormStore<ExperienceSettingsFormState, ExperienceSettingsFormMutation>
}

function ExperienceSettingsRegistrationFormFields({
  form,
  testid = "ExperienceSettingsRegistrationFormFields",
}: Props) {
  const WAITLIST_CTA_LABEL = "Join the Waitlist"
  const membersLabel = useLabel("organization_member")
  const experienceLabel = useLabel("experience")
  const classes = useStyles()

  return (
    <>
      <DiscoFormControl
        label={
          <ExperienceSettingsLabel
            title={"Registration Status"}
            tooltipContent={
              "Modify registration status to either allow registrations, close (block) registrations or link to a waitlist form."
            }
            sectionId={"experience_registration_status"}
          />
        }
        description={`Specify how ${membersLabel.plural} register for the ${experienceLabel.singular}.`}
        error={Boolean(form.errorsByField.registrationType)}
        errorMessages={form.errorsByField.registrationType}
        variant={"two-column"}
        marginBottom={form.state.registrationType === "closed" ? 0 : undefined}
      >
        <DiscoTabs
          testid={`${testid}.registrationType.tabs`}
          tabVariant={"grey-track"}
          classes={{ arrowsContainer: classes.tabs }}
          routes={[
            {
              label: "Open",
              onClick: () => (form.state.registrationType = "open"),
              active:
                form.state.registrationType === "open" ||
                form.state.registrationType === "application",
              testid: "open",
            },
            {
              label: "Waitlist",
              onClick: () => (form.state.registrationType = "waitlist"),
              active: form.state.registrationType === "waitlist",
              testid: "waitlist",
            },
            {
              label: "Closed",
              onClick: () => (form.state.registrationType = "closed"),
              active: form.state.registrationType === "closed",
              testid: "closed",
            },
          ]}
        />
      </DiscoFormControl>
      {(form.state.registrationType === "open" ||
        form.state.registrationType === "application") && (
        <FormControl fullWidth>
          <DiscoSwitch
            testid={`${testid}.registrationType.switch.application`}
            label={
              <ExperienceSettingsLabel
                title={"Application Required"}
                sectionId={"experience_application"}
                tooltipContent={`Add a mandatory application before ${membersLabel.plural} gain access to your ${experienceLabel.singular}.  When a ${membersLabel.singular} submits an application, they will not gain access to any content until you have approved them.  Payment (if any) is taken on approval.`}
              />
            }
            sublabel={
              <DiscoText variant={"body-sm"} marginBottom={1}>
                {"Require an application to be completed and approved before entry."}
              </DiscoText>
            }
            checked={form.state.registrationType === "application"}
            onChange={(checked) =>
              (form.state.registrationType = checked ? "application" : "open")
            }
          />
          {form.state.registrationType === "application" && (
            <>
              {form.state.applicationQuestions?.map((question, index) => (
                <QuestionFormField
                  key={question.id}
                  label={`Question ${index + 1}`}
                  question={question}
                  questionOrder={index}
                  onContentChange={(v) => {
                    handleRichEditorQuestionBodyTextChange(v, index)
                  }}
                  onRemoveQuestion={() => handleRemoveQuestion(index)}
                />
              ))}

              <DiscoButton
                testid={`${testid}.application.add-question-button`}
                color={"grey"}
                variant={"dashed"}
                width={"100%"}
                onClick={handleAddQuestion}
                leftIcon={"add-circle"}
              >
                {"Add Question"}
              </DiscoButton>
            </>
          )}
        </FormControl>
      )}
      {form.state.registrationType === "waitlist" && (
        <>
          <DiscoFormControl
            fullWidth
            title={"Waitlist URL"}
            sublabel={`${membersLabel.plural} are sent to this URL when they attempt to register for this ${experienceLabel.singular}.`}
          >
            <DiscoLinkInput
              data-testid={`${testid}.waitlist.url`}
              name={"waitlist-url"}
              value={form.state.waitlistUrl}
              onChange={(val) => (form.state.waitlistUrl = val)}
              placeholder={"abcdef.typeform.com/to/ghijkl"}
            />
          </DiscoFormControl>
          <DiscoFormControl
            fullWidth
            title={"Waitlist CTA Label"}
            sublabel={`Optionally, customize the label for the waitlist button. Defaults to '${WAITLIST_CTA_LABEL}'.`}
          >
            <DiscoInput
              data-testid={`${testid}.waitlist.cta-label`}
              name={"waitlist-cta-label"}
              value={form.state.waitlistCtaLabel}
              onChange={(e) => (form.state.waitlistCtaLabel = e.target.value)}
              placeholder={WAITLIST_CTA_LABEL}
            />
          </DiscoFormControl>
        </>
      )}
    </>
  )

  function handleRichEditorQuestionBodyTextChange(value: string, index: number) {
    if (!form.state.applicationQuestions) return
    form.state.applicationQuestions[index].richEditorBody = value
  }

  function handleAddQuestion() {
    form.state.applicationQuestions?.push({
      id: uuidv4(),
      richEditorBody: null,
      type: "free-text",
      isRequired: true,
    })
  }

  function handleRemoveQuestion(index: number) {
    if (!form.state.applicationQuestions) return
    // must have at least one question
    if (form.state.applicationQuestions.length === 1) return
    form.state.applicationQuestions.splice(index, 1)
  }
}

const useStyles = makeUseStyles((theme) => ({
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
  tabs: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))

export const ExperienceSettingsRegistrationFormFieldsSkeleton = () => {
  const classes = useStyles()
  const heights = [138, 96]

  return (
    <div className={classes.skeletonContainer}>
      {heights.map((height) => (
        <LabelFormFieldSkeleton key={height} height={height} width={350} />
      ))}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperienceSettingsRegistrationFormFields),
  skeleton: ExperienceSettingsRegistrationFormFieldsSkeleton,
})
