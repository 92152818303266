/**
 * @generated SignedSource<<a893d3ae0df4a20d7d65d3ec87f9b25c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegistrationExperienceDetailsFormFragment$data = {
  readonly id: string;
  readonly cover: string;
  readonly richEditorCheckoutDescription: string | null;
  readonly " $fragmentType": "RegistrationExperienceDetailsFormFragment";
};
export type RegistrationExperienceDetailsFormFragment$key = {
  readonly " $data"?: RegistrationExperienceDetailsFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegistrationExperienceDetailsFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegistrationExperienceDetailsFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorCheckoutDescription",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "b8eadd1a88ad193e40a630e7e279fe68";

export default node;
