import useIsWebView from "@/product/util/hook/useIsWebView"

/**
 * Returns true if the user has given consent to be tracked on the mobile app
 */
export function useTrackingTransparency() {
  // Tracking transparency is only relevant for webviews
  const isWebView = useIsWebView()
  if (!isWebView) return true

  // Check if the webview has the tracking meta tag
  const canTrack = document.querySelector('meta[name="tracking"]')
  return Boolean(canTrack)
}
