import { isWebView } from "@utils/webView/webViewUtils"

/**
 * Check if the page is being rendered as a webview
 */
function useIsWebView(): boolean {
  return isWebView()
}

export function isWebViewContentPage() {
  return location.pathname.includes("/webview")
}

export default useIsWebView
