import ErrorBoundary from "@/core/error/ErrorBoundary"
import { isDevelopmentEnv } from "@utils/globalVariables"
import { camelize } from "inflected"
import { get } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { UseMutationConfig, useLazyLoadQuery, useMutation } from "react-relay"
import {
  CacheConfig,
  ConnectionHandler,
  FetchPolicy,
  FetchQueryFetchPolicy,
  GraphQLTaggedNode,
  MutationParameters,
  OperationType,
  RecordProxy,
  RecordSourceProxy,
  VariablesOf,
  fetchQuery,
} from "relay-runtime"
import { v4 as uuidv4 } from "uuid"
import RelayEnvironment from "./RelayEnvironment"
import { Connection, GlobalID, RelayNode } from "./RelayTypes"

namespace Relay {
  export type RefetchCallback<T extends OperationType> = (
    v: VariablesOf<T>,
    opts?: RefetchOptions
  ) => Promise<T["response"] | null>

  interface RefetchableQueryOptions {
    refetchInBackground?: boolean
    fetchPolicy?: FetchPolicy
  }

  interface RefetchablePaginationQueryOptions {
    connectionName: string
    refetchInBackground?: boolean
    fetchPolicy?: FetchPolicy
  }

  const DEFAULT_PAGE_SIZE = 10

  interface RefetchOptions {
    inBackground?: boolean
  }

  type UseRefetchablePaginationVariables = {
    first?: number
    search?: string | null
  }

  export type UseRefetchablePaginationCallback = (
    v: UseRefetchablePaginationVariables
  ) => Promise<void>

  export type UseRefetchablePaginationQueryResponse<T extends OperationType> = {
    data: T["response"] | null
    pagination: {
      isLoading: boolean
      hasNext: boolean
      hasPrevious: boolean
      refetch: UseRefetchablePaginationCallback
      loadMore: UseRefetchablePaginationCallback
    }
  }

  /**
   * Executes a query and returns callback to refetch results with new variables.
   * When calling refetch, inBackground can be set as an option to
   * continue showing the current query results while the query re-fetches.
   * @returns The query response
   * @returns A refetch callback that takes new variables for the query
   */
  export function useRefetchableQuery<T extends OperationType>(
    query: GraphQLTaggedNode,
    currentVariables: VariablesOf<T>,
    opts: RefetchableQueryOptions = { refetchInBackground: false }
  ): [T["response"], RefetchCallback<T>, boolean] {
    // These are the options passed to useLazyLoadQuery. By setting
    // the query options you can trigger a re-fetch of useLazyLoadQuery.
    const [queryOptions, setQueryOptions] = useState<{
      variables: VariablesOf<T> | null
      fetchOptions: { fetchKey?: string; fetchPolicy: FetchPolicy }
    }>({
      variables: null,
      fetchOptions: {
        fetchPolicy: opts.fetchPolicy || "store-or-network",
      },
    })

    const [isLoading, setIsLoading] = useState(false)

    const { current: initialVariables } = useRef(currentVariables)
    // Use variables from a refetch({ ... }) call, otherwise
    // inBackground should not trigger suspense by changing variables.
    const queryVariables =
      queryOptions.variables ??
      (opts.refetchInBackground ? initialVariables : currentVariables)

    const queryResponse = useLazyLoadQuery<T>(
      query,
      queryVariables,
      queryOptions.fetchOptions
    )
    const refetch = useCallback(
      async (variables: VariablesOf<T>, refetchOpts: RefetchOptions = {}) => {
        // The specific call to refetch can override the hook-level refetchInBackground
        setIsLoading(true)
        const inBackground = refetchOpts.inBackground ?? opts.refetchInBackground
        if (inBackground) {
          // Re-execute the query without triggering useLazyLoadQuery
          // to suspend, so loading state doesn't show.
          const res = await Relay.runQuery(query, variables, {
            fetchPolicy: "network-only",
          })
          // Update to make useLazyLoadQuery grab new values
          setQueryOptions({
            variables,
            fetchOptions: { fetchPolicy: "store-only" },
          })
          setIsLoading(false)
          return res
        }
        // Set a new fetchKey to force useLazyLoadQuery
        // to re-execute and suspend, showing loading state
        setQueryOptions({
          variables,
          fetchOptions: {
            fetchKey: uuidv4(),
            fetchPolicy: "network-only",
          },
        })
        setIsLoading(false)

        return null
      },
      // Eslint thinks "T" is a value that needs to be in here...
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [opts.refetchInBackground, query, setQueryOptions]
    )

    return [queryResponse, refetch, isLoading]
  }

  /**
   * A wrapper around useRefetchableQuery that provides similar functionality to
   *   usePaginationFragment, but with the ability to refetch the query with new
   *   custom variables like `after` and `first`, etc.
   * By default, this will refetch the query in the background, but this can be
   *  customized by passing `refetchInBackground: false` in the options.
   */
  export function useRefetchablePaginationQuery<T extends OperationType>(
    query: GraphQLTaggedNode,
    variables: Omit<VariablesOf<T>, keyof UseRefetchablePaginationVariables> &
      UseRefetchablePaginationVariables,
    opts: RefetchablePaginationQueryOptions
  ): UseRefetchablePaginationQueryResponse<T> {
    // Supply a default page size so we don't have to pass it in every time
    // we call this hook
    const initialVariables = {
      first: DEFAULT_PAGE_SIZE,
      ...variables,
    }
    // Force null instead of a blank string so Relay will reuse the same connection in both cases
    if (variables.search === "") initialVariables.search = null

    const { connectionName, refetchInBackground = true } = opts

    const [data, refetch, isLoading] = Relay.useRefetchableQuery<T>(
      query,
      initialVariables,
      { refetchInBackground }
    )

    // Get the connection using the provded name
    const connection = get(data, connectionName) as Connection | undefined
    const pageInfo = connection?.pageInfo
    if (!pageInfo) throw new Error("No pageInfo found in connection.")

    const refetchWithInitialVariables = useCallback(
      async (v: UseRefetchablePaginationVariables = {}) => {
        const newVars = {
          ...initialVariables,
          ...v,
          // Reset the cursor to the beginning of the list when refetching
          after: null,
        }
        // Force null instead of a blank string so Relay will reuse the same connection in both cases
        if (v.search === "") newVars.search = null
        await refetch(newVars)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [variables, refetch]
    )

    const loadMoreWithInitialVariables = useCallback(
      async (v: UseRefetchablePaginationVariables = {}) => {
        const newVars = {
          ...initialVariables,
          ...v,
          // Use the current endCursor to load more
          after: pageInfo.endCursor,
        }
        // Force null instead of a blank string so Relay will reuse the same connection in both cases
        if (v.search === "") newVars.search = null
        await refetch(newVars)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [variables, refetch, pageInfo.endCursor]
    )

    return {
      data,
      pagination: {
        isLoading,
        hasNext: pageInfo.hasNextPage,
        hasPrevious: pageInfo.hasPreviousPage,
        refetch: refetchWithInitialVariables,
        loadMore: loadMoreWithInitialVariables,
      },
    }
  }

  /**
   * A query that returns null instead of suspending while running. Useful
   * for queries that don't need need to block their children.
   */
  export function useBackgroundQuery<T extends OperationType>(
    query: GraphQLTaggedNode,
    variables: VariablesOf<T>,
    opts: { fetchPolicy?: FetchQueryFetchPolicy } = {}
  ) {
    // Fetch the query in the background on first load and when variables change.
    useEffect(() => {
      Relay.runQuery(query, variables, {
        fetchPolicy: opts?.fetchPolicy || "store-or-network",
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, variables, opts?.fetchPolicy])

    return useLazyLoadQuery<T>(query, variables, {
      // We only grab from the store because the useEffect
      // is responsible for updating the store in the background.
      fetchPolicy: "store-only",
    })
  }

  /**
   * Wrapper around useLazyLoadQuery that adds the option to skip the query entirely,
   * including the network request.
   */
  export function useSkippableLazyLoadQuery<T extends OperationType>(
    query: GraphQLTaggedNode,
    variables: VariablesOf<T>,
    options: {
      skip?: boolean
      fetchKey?: string | number | undefined
      fetchPolicy?: FetchPolicy | undefined
      networkCacheConfig?: CacheConfig | undefined
    } = {}
  ): Partial<T["response"]> {
    const { skip = false, ...opts } = options
    const response = useLazyLoadQuery<T>(query, variables, {
      ...opts,
      // Hack to skip the request entirely. Apparenly there used to be a built-in option
      // to do this, but Meta removed it without explaining why.
      // https://github.com/facebook/relay/issues/4517
      fetchPolicy: skip ? "store-only" : opts?.fetchPolicy,
    })
    // When skipping, don't return the data if it happens to already be in the Relay store
    return skip ? {} : response
  }

  /**
   * Same as useBackgroundQuery except also allows refetching the query
   */
  export function useRefetchableBackgroundQuery<T extends OperationType>(
    query: GraphQLTaggedNode,
    variables: VariablesOf<T>,
    opts: { fetchPolicy?: FetchQueryFetchPolicy } = {}
  ): [T["response"], RefetchCallback<T>] {
    const response = useBackgroundQuery(query, variables, opts)
    const refetch = useCallback(
      (vars: VariablesOf<T>) =>
        Relay.runQuery(query, vars, { fetchPolicy: "network-only" }),
      [query]
    )
    return [response, refetch]
  }

  /** Convert relay globalId to its integer raw value */
  export function toIntegerId(globalId: string | null | undefined): number | null {
    if (!globalId) return null
    const { id } = fromGlobalId(globalId)
    const intId = parseInt(id)
    if (isNaN(intId)) return null
    return intId
  }

  /** Get the Raw ID from a GlobalID */
  export function rawId(globalId: string): string {
    const { id } = fromGlobalId(globalId)
    return id
  }

  /** Encode a type + raw ID to a relay GlobalID */
  export function toGlobalId(type: string, id: string | number): GlobalID {
    return btoa(`${type}:${id}`)
  }

  /** Parse a relay GlobalID to its type + raw ID */
  export function fromGlobalId(globalId: GlobalID): { type: string; id: string } {
    const [type, id] = atob(globalId).split(":")
    if (!type || !id) throw new Error(`Invalid globalId ${globalId}`)
    return { id, type }
  }

  /** Assert a node's *__typename* is type */
  export function isNodeType<T extends string>(
    node: { readonly __typename: string } | null | undefined,
    type: T
  ): node is { readonly __typename: T } {
    return node?.__typename === type
  }

  export type NarrowNode<
    TNode extends { readonly __typename: string } | null | undefined,
    TNodeType extends string
  > = TNode extends { readonly __typename: TNodeType } ? TNode : never

  type ExtractNodeTypename<TNode> = TNode extends { readonly __typename: infer T }
    ? Exclude<T, "%other">
    : never

  export function narrowNodeType<
    TNode extends { readonly __typename: string } | null,
    TNodeType extends ExtractNodeTypename<TNode>
  >(node: TNode, type: TNodeType) {
    if (!isNodeType(node, type)) return null
    return node as NarrowNode<TNode, TNodeType>
  }

  interface WithSkeletonArgs<T> {
    component: React.FC<T>
    skeleton: React.FC<T>
    /** Development testing option to always show skeleton */
    skeletonOnly?: boolean
  }

  /** Wrap a component with a Suspense-based skeleton loader and ErrorBoundary */
  export function withSkeleton<T extends object>(args: WithSkeletonArgs<T>): React.FC<T> {
    return (props) => (
      <ErrorBoundary>
        <React.Suspense fallback={React.createElement(args.skeleton, props)}>
          {args.skeletonOnly && isDevelopmentEnv()
            ? React.createElement(args.skeleton, props)
            : React.createElement(args.component, props)}
        </React.Suspense>
      </ErrorBoundary>
    )
  }

  /** Wrapper around useMutation that makes the Relay commitMutation return a promise */
  export function useAsyncMutation<T extends MutationParameters>(
    mutation: GraphQLTaggedNode
  ): (
    variables: T["variables"],
    config?: Omit<UseMutationConfig<T>, "variables" | "onCompleted" | "onError">
  ) => Promise<T["response"]> {
    const [commitMutation] = useMutation<T>(mutation)
    return useCallback(
      (variables, config) => {
        return new Promise((resolve, reject) => {
          commitMutation({
            variables,
            onCompleted(res, errs) {
              if (errs) return reject(errs[0])
              if (!res) return reject(new Error("Unexpected missing response"))
              return resolve(res)
            },
            onError: reject,
            ...config,
          })
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [commitMutation]
    )
  }

  /** Execute a programmatic graphql query */
  export function runQuery<Q extends OperationType>(
    query: GraphQLTaggedNode,
    variables: VariablesOf<Q>,
    cacheConfig?: {
      networkCacheConfig?: CacheConfig | null | undefined
      fetchPolicy?: FetchQueryFetchPolicy | null | undefined
    } | null
  ) {
    return fetchQuery(RelayEnvironment, query, variables, cacheConfig).toPromise()
  }

  /** Convert a GraphQLConnection to a standard array */
  export function connectionToArray<T extends TNode, TNode = RelayNode>(
    connection: Connection<T> | undefined | null
  ): T[] {
    if (!connection?.edges) return []

    /**
     * When deleting a node with the @deleteRecord directive,
     * the node in the connection becomes null causing a lot of
     * 'cannot read x of undefined' errors so filter
     * out these null nodes
     */
    return connection.edges.reduce((nodes: T[], edge) => {
      if (edge.node) {
        nodes.push(edge.node)
      }
      return nodes
    }, [])
  }

  export function connectionToMap<T extends RelayNode>(
    connection: Connection<T> | undefined | null
  ): { [id: GlobalID]: T } {
    if (!connection?.edges) return {}
    // filter null nodes, see note in connectionToArray above
    return connection.edges.reduce<{ [id: GlobalID]: T }>((nodes, edge) => {
      if (edge.node) nodes[edge.node.id] = edge.node
      return nodes
    }, {})
  }

  /** Insert a node into a paginated connection at a specified index */
  export function insertNodeIntoPaginatedConnection(
    store: RecordSourceProxy,
    connection: RecordProxy,
    node: RecordProxy,
    indexToInsertAt?: number | null
  ) {
    const edges = connection.getLinkedRecords("edges")!

    // Increment connection totalCount
    const currentCount = (connection.getValue("totalCount") || 0) as number
    connection.setValue(currentCount + 1, "totalCount")

    // If the connection is not loaded up to the insertion point
    // then we do not need to insert the new node into the connection
    // ex. the connection is paginated and we are inserting past the end of the current page
    if (edges && typeof indexToInsertAt === "number" && edges.length < indexToInsertAt)
      return

    const newEdge = ConnectionHandler.createEdge(store, connection, node, `${node}Edge`)

    // If inserting edge at beginning of connection
    if (typeof indexToInsertAt === "number" && indexToInsertAt === 0) {
      ConnectionHandler.insertEdgeBefore(connection, newEdge)
      return
    }

    const newEdges = [...edges]

    // Insert newEdge at indexToInsertAt
    if (typeof indexToInsertAt === "number") {
      newEdges.splice(indexToInsertAt, 0, newEdge)
    } else {
      newEdges.push(newEdge)
    }

    connection.setLinkedRecords(newEdges, "edges")
  }

  /** Create a new node in the store with the provided type and values */
  export function fabricateNode<T extends Record<string, any>>(
    store: RecordSourceProxy,
    type: string,
    values: T
  ) {
    const id = values.id || uuidv4()

    // If the node already exists in the store, return it
    const existingNode = store.get(toGlobalId(type, id))
    if (existingNode) return existingNode

    // Otherwise create a new node
    const node = store.create(toGlobalId(type, id), type)
    deepUpdate(store, node, values)
    return node
  }

  /** Create a new connection in the store with the provided type and values */
  export function fabricateConnection<T extends Record<string, any>>(
    store: RecordSourceProxy,
    type: string,
    values: T[]
  ) {
    const connection = store.create(toGlobalId(type, uuidv4()), `${type}NodeConnection`)

    const edges = []
    for (const value of values) {
      const id = value.id || uuidv4()
      const edge = fabricateNode(store, `${type}NodeEdge`, {})
      const node = fabricateNode(store, type, { id, ...value })
      edge.setLinkedRecord(node, "node")
      edges.push(edge)
    }

    connection.setLinkedRecords(edges, "edges")
    connection.setValue(edges.length, "totalCount")
    return connection
  }

  export function updateFabricatedNode<T extends Record<string, any>>(
    store: RecordSourceProxy,
    id: GlobalID,
    values: T
  ) {
    const node = store.get(id)
    if (!node) return
    deepUpdate(store, node, values)
  }

  /** Move a node from one paginated connection to another paginated connection at a specified index */
  export function moveNodeBetweenPaginatedConnections(
    store: RecordSourceProxy,
    sourceConnection: RecordProxy,
    destinationConnection: RecordProxy,
    nodeId: GlobalID,
    indexToInsertAt: number
  ) {
    // Remove node from source connection
    deleteNodeFromConnection(sourceConnection, nodeId)

    const node = store.get(nodeId)
    if (!node) return

    insertNodeIntoPaginatedConnection(store, destinationConnection, node, indexToInsertAt)
  }

  /** Reorder a given edge within a paginated connection */
  export function reorderEdgeInPaginatedConnection(
    store: RecordSourceProxy,
    connection: RecordProxy,
    nodeId: GlobalID,
    toIndex: number
  ) {
    moveNodeBetweenPaginatedConnections(store, connection, connection, nodeId, toIndex)
  }

  /** Delete the provided Node ID from the connection and decrement totalCount */
  export function deleteNodeFromConnection(
    connection: RecordProxy,
    deleteNode: GlobalID
  ) {
    const edges = connection.getLinkedRecords("edges")
    if (!edges) return
    const filteredEdges = edges.filter((edge) => {
      const nodeId = edge.getLinkedRecord("node")?.getValue("id")
      return Boolean(nodeId) && nodeId !== deleteNode
    })
    connection.setLinkedRecords(filteredEdges, "edges")

    const currentCount = (connection.getValue("totalCount") || 1) as number
    connection.setValue(currentCount - 1, "totalCount")
  }

  /** Remove the provided Node ID from the source connection and add it to the destination connection at a specified destinationIndex
   * and decrement totalCount for the sourceConnection and increment totalCount for the destinationConnection */

  export function moveNodeBetweenConnections(
    sourceConnection: RecordProxy,
    destinationConnection: RecordProxy,
    addNodeId: GlobalID,
    indexToInsertAt: number
  ) {
    const sourceEdges = sourceConnection.getLinkedRecords("edges")
    const destinationEdges = destinationConnection.getLinkedRecords("edges")

    if (!sourceEdges || !destinationEdges) return

    const filteredEdges = sourceEdges.filter((edge) => {
      const nodeId = edge.getLinkedRecord("node")?.getValue("id")
      return Boolean(nodeId) && nodeId === addNodeId
    })

    const newDestinationEdges = [...destinationEdges]

    newDestinationEdges.splice(indexToInsertAt, 0, ...filteredEdges)

    destinationConnection.setLinkedRecords(newDestinationEdges, "edges")

    const currentDestinationCount = (destinationConnection.getValue("totalCount") ||
      1) as number
    destinationConnection.setValue(currentDestinationCount + 1, "totalCount")

    deleteNodeFromConnection(sourceConnection, addNodeId)
  }

  /** Reorder a given edge within the connection */
  export function reorderEdgeInConnection(
    connection: RecordProxy,
    fromIndex: number,
    toIndex: number
  ) {
    const edges = connection.getLinkedRecords("edges")
    if (!edges) return
    const newEdges = [...edges]
    const [moving] = newEdges.splice(fromIndex, 1)
    newEdges.splice(toIndex, 0, moving)
    connection.setLinkedRecords(newEdges, "edges")
  }

  /** Perform a recursive update on the provided Store record */
  export function deepUpdate(
    store: RecordSourceProxy,
    record: RecordProxy,
    values: Record<string, any>
  ) {
    Object.keys(values).forEach((key) => {
      const isNestedObject =
        typeof values[key] === "object" &&
        !Array.isArray(values[key]) &&
        values[key] !== null
      if (!isNestedObject) {
        record.setValue(values[key], key)
        return
      }
      // If the linked object already exists in store by GlobalID, reference it.
      const existingLink = store.get(values[key]?.id ?? "")
      if (existingLink) {
        record.setLinkedRecord(existingLink, key)
        deepUpdate(store, existingLink, values[key])
        return
      }
      // Otherwise get or create a new linked record.
      const linked = record.getOrCreateLinkedRecord(key, camelize(key, true))
      deepUpdate(store, linked, values[key])
    })
  }

  /** Remove readonly from properties of a given type */
  export type Writeable<T> = { -readonly [k in keyof T]: T[k] }

  export type DeepWriteable<T> = T extends
    | Record<string, unknown>
    | ReadonlyArray<unknown>
    ? { -readonly [k in keyof T]: DeepWriteable<T[k]> }
    : T
}

export default Relay
