import { useLabel } from "@/core/context/LabelsContext"
import ExperienceAdminsListItem, {
  ExperienceAdminsListItemSkeleton,
} from "@/product/settings/ExperienceAdminsListItem"
import { ExperienceAdminsListQuery } from "@/product/settings/__generated__/ExperienceAdminsListQuery.graphql"
import { ExperienceAdminsList_PaginationFragment$key } from "@/product/settings/__generated__/ExperienceAdminsList_PaginationFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoTable, DiscoTableSkeletonWithHeader } from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import {
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { graphql } from "relay-runtime"
import ProductMembersListFloatingBar from "../member/common/list/floating-bar/ProductMembersListFloatingBar"

interface Props {
  productId: GlobalID
  toolbarState: ExperienceAdminsListToolbarState
}

export type ExperienceAdminsListToolbarState = {
  search: string
}

const MEMBERSHIPS_PER_PAGE = 5

function ExperienceAdminsList({ productId, toolbarState }: Props) {
  const [activePage, setActivePage] = useState(1)
  const productLabel = useLabel("admin_experience")
  const isXsDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"))
  const [memberships, setMemberships] = useState<{ id: GlobalID; memberId: GlobalID }[]>(
    []
  )
  const { product } = useLazyLoadQuery<ExperienceAdminsListQuery>(
    graphql`
      query ExperienceAdminsListQuery(
        $id: ID!
        $first: Int!
        $after: String
        $last: Int
        $before: String
        $search: String
      ) {
        product: node(id: $id) {
          ... on Product {
            ...ExperienceAdminsList_PaginationFragment
              @arguments(
                first: $first
                after: $after
                last: $last
                before: $before
                search: $search
              )
          }
        }
      }
    `,
    {
      first: MEMBERSHIPS_PER_PAGE,
      id: productId,
      search: toolbarState.search,
    },
    { fetchPolicy: "store-and-network" }
  )

  const { data, refetch } = usePaginationFragment<
    ExperienceAdminsListQuery,
    ExperienceAdminsList_PaginationFragment$key
  >(
    graphql`
      fragment ExperienceAdminsList_PaginationFragment on Product
      @refetchable(queryName: "ExperienceAdminsListPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
        search: { type: "String" }
      ) {
        productMemberships(
          roles: [manager, instructor]
          first: $first
          after: $after
          last: $last
          before: $before
          search: $search
          orderBy: { field: "role", direction: ASC }
        ) @connection(key: "ExperienceAdminsList__productMemberships") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...ExperienceAdminsListItemFragment
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    product
  )

  useSubscribeToInvalidationState(
    data?.productMemberships?.__id ? [data.productMemberships.__id] : [],
    refetchMemberships
  )

  if (!data) return null
  const adminMemberships = Relay.connectionToArray(data.productMemberships)

  return (
    <>
      <DiscoTable
        testid={"ExperienceAdminsList"}
        sectionProps={{ padding: 0 }}
        activePage={activePage}
        setActivePage={setActivePage}
        rowsPerPage={MEMBERSHIPS_PER_PAGE}
        onPaginate={refetch}
        filterDeps={[toolbarState]}
        connection={{
          cursorsList: data.productMemberships.edges.map((e) => e.cursor),
          totalCount: data.productMemberships.totalCount,
          pageInfo: {
            endCursor: data.productMemberships.pageInfo.endCursor,
            startCursor: data.productMemberships.pageInfo.startCursor,
          },
        }}
        header={[
          { value: "" },
          { value: "Name" },
          ...ArrayUtils.spreadIf({ value: "Community Role" }, !isXsDown),
          { value: `${productLabel.singular} Role` },
          { value: "Actions" },
        ]}
        rows={adminMemberships.map((pm, i) => (
          <ExperienceAdminsListItem
            key={pm.id}
            testid={`ExperienceAdminsListItem.${i}`}
            productMembershipKey={pm}
            selected={isMembershipSelected(pm.id)}
            onToggle={handleSelectMember}
          />
        ))}
      />

      {/* Compose Email Bar */}
      <ProductMembersListFloatingBar
        memberships={memberships}
        onClose={closeFloatingBar}
        hideGroupsButton
      />
    </>
  )

  function closeFloatingBar() {
    setMemberships([])
  }

  function refetchMemberships() {
    refetch(
      {
        search: toolbarState.search,
        first: activePage * MEMBERSHIPS_PER_PAGE,
      },
      { fetchPolicy: "network-only" }
    )
  }

  function isMembershipSelected(productMembershipId: GlobalID) {
    return memberships.map((sm) => sm.id).includes(productMembershipId)
  }

  function handleSelectMember(productMembership: { id: string; memberId: string }) {
    if (isMembershipSelected(productMembership.id)) {
      // de-select
      const newSelectedMemberships = memberships.filter(
        (m) => m.id !== productMembership.id
      )
      setMemberships(newSelectedMemberships)
    } else {
      // select
      setMemberships([
        ...memberships,
        {
          id: productMembership.id,
          memberId: productMembership.memberId,
        },
      ])
    }
  }
}

export const ExperienceAdminsListSkeleton = () => {
  const productLabel = useLabel("admin_experience")

  return (
    <>
      <DiscoTableSkeletonWithHeader
        sectionProps={{ padding: 0 }}
        header={[
          { value: "" },
          { value: "Name" },
          { value: "Community Role" },
          { value: `${productLabel.singular} Role` },
          { value: "Actions" },
        ]}
        rows={5}
        row={<ExperienceAdminsListItemSkeleton />}
      />
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperienceAdminsList),
  skeleton: ExperienceAdminsListSkeleton,
})
