import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import { ProductRegistrationSuccessStepQuery } from "@/product/register/steps/__generated__/ProductRegistrationSuccessStepQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoSpinner,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams, useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useParams } from "react-router-dom"
import { graphql } from "relay-runtime"
interface Props extends TestIDProps {
  store: CheckoutStore
}

function ProductRegistrationSuccessStep({
  store,
  testid = "ProductRegistration",
}: Props) {
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const drawer = useGlobalDrawer("registration")
  const experienceLabel = useLabel("experience")
  const { membershipPlanSlug, inviteToken } = useQueryParams()
  const { productSlug } = useParams<{ productSlug: string }>()

  // Need to set the `viewerMembership` for new logged in users
  const { product } = useLazyLoadQuery<ProductRegistrationSuccessStepQuery>(
    graphql`
      query ProductRegistrationSuccessStepQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            cover
            name
            type
            slug
            viewerMembership {
              id
              organizationMembership {
                id
                hasSeenOnboarding
                viewerShouldCompleteMemberOnboarding
              }
            }
          }
        }
      }
    `,
    {
      id: store.product?.id || "",
    }
  )

  const organizationMembership = product?.viewerMembership?.organizationMembership
  const shouldRedirectToOnboarding =
    !store.checkout?.hasApplication &&
    organizationMembership &&
    !organizationMembership.hasSeenOnboarding &&
    organizationMembership.viewerShouldCompleteMemberOnboarding

  useEffect(() => {
    if (!shouldRedirectToOnboarding) return
    const url = new URL(window.location.href)
    const destination = generateURL()
    Object.assign<URL, Partial<Pick<URL, "origin" | "pathname">>>(url, destination)
    location.href = url.origin + url.pathname
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRedirectToOnboarding])

  if (!product || shouldRedirectToOnboarding) return <DiscoSpinner />

  return (
    <div className={classes.container}>
      {product.cover && <DiscoImage src={product.cover} className={classes.cover} />}

      <DiscoText
        testid={`${testid}SuccessStep.success-title`}
        variant={"heading-lg"}
        paddingBottom={1}
        className={classes.text}
      >
        {store.checkout?.hasApplication
          ? "Application Received! Hang tight"
          : "Congratulations!"}
      </DiscoText>

      <DiscoText
        testid={`${testid}SuccessStep.success-message`}
        color={"text.secondary"}
        paddingBottom={4}
        className={classes.text}
      >
        {getSuccessText()}
      </DiscoText>

      {!store.checkout?.hasApplication && (
        <DiscoButton
          testid={`${testid}SuccessStep.cta-link`}
          to={generateURL()}
          // Needs to be _top or else the page will infinitely re-render
          // top targets the outter-most frame aka: window, see https://stackoverflow.com/a/63759718
          target={drawer.isOpen ? "_self" : "_top"}
        >
          {getButtonText()}
        </DiscoButton>
      )}
    </div>
  )

  function getSuccessText() {
    if (store.checkout?.hasApplication)
      return `You successfully applied for ${product?.name}.`

    switch (product?.type) {
      case "membership_plan":
        return `You successfully registered for ${product?.name}.`
      case "community_event":
        return `You successfully registered for ${product?.name}. You'll now have access to it on the events page.`
      case "course":
      default:
        return `You successfully registered for ${product?.name}. You'll now have access to it under ${experienceLabel.plural}.`
    }
  }

  function getButtonText() {
    switch (product?.type) {
      case "membership_plan":
        return `Continue to ${activeOrganization?.name}`
      case "community_event":
        return "Continue to Events"
      case "course":
      default:
        return `Continue to ${experienceLabel.singular}`
    }
  }

  function generateURL() {
    if (product?.type === "membership_plan") {
      // If registering for an experience, but had to register for a
      // membership plan, redirect to the experience drawer
      if (membershipPlanSlug && productSlug && activeProduct)
        return {
          pathname: ROUTE_NAMES.COMMUNITY.EXPERIENCES.UPCOMING,
          search: setSearchParams<
            GlobalDrawerParams<"registration"> & { inviteToken?: string }
          >("", {
            drawerRegistrationExperienceId: activeProduct.id,
            inviteToken,
          }),
        }
      return {
        pathname: ROUTE_NAMES.COMMUNITY.HOME.ROOT,
      }
    }
    return {
      pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
        productSlug: product!.slug!,
      }),
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(3),
  },
  cover: {
    borderRadius: theme.measure.borderRadius.big,
    marginBottom: theme.spacing(3),
    maxWidth: "850px",
  },
  text: {
    textAlign: "center",
  },
}))

export const ProductRegistrationSuccessStepSkeleton: React.FC<{
  store: CheckoutStore
}> = (props) => {
  const { store } = props
  const classes = useStyles()
  const activeProduct = useActiveProduct()

  return (
    <div className={classes.container}>
      {activeProduct?.cover && (
        <div style={{ width: "100%" }}>
          <Skeleton
            variant={"rect"}
            width={"100%"}
            height={"350px"}
            className={classes.cover}
          />
        </div>
      )}
      <DiscoTextSkeleton paddingBottom={1} height={"60px"} width={"200px"} />
      <DiscoTextSkeleton width={"100%"} height={"200px"} paddingBottom={4} />
      {!store.checkout?.hasApplication && <DiscoButtonSkeleton />}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ProductRegistrationSuccessStep),
  skeleton: ProductRegistrationSuccessStepSkeleton,
})
