/**
 * @generated SignedSource<<20251541c51e398ede57067be777f136>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductAddAppDropdownContentQuery$variables = {
  id: string;
};
export type ProductAddAppDropdownContentQuery$data = {
  readonly product: {
    readonly " $fragmentSpreads": FragmentRefs<"appLevelContextFragment">;
  } | null;
};
export type ProductAddAppDropdownContentQuery = {
  variables: ProductAddAppDropdownContentQuery$variables;
  response: ProductAddAppDropdownContentQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductAddAppDropdownContentQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "appLevelContextFragment"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductAddAppDropdownContentQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": "connectedApps",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "includeNested",
                    "value": true
                  }
                ],
                "concreteType": "ProductAppNodeConnection",
                "kind": "LinkedField",
                "name": "productApps",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductAppNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductApp",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "productApps(includeNested:true)"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e85b9be9bfc4d20a6df641a698cdb5cd",
    "id": null,
    "metadata": {},
    "name": "ProductAddAppDropdownContentQuery",
    "operationKind": "query",
    "text": "query ProductAddAppDropdownContentQuery(\n  $id: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    ... on Product {\n      ...appLevelContextFragment\n    }\n    id\n  }\n}\n\nfragment appLevelContextFragment on Product {\n  id\n  name\n  slug\n  type\n  ...useConnectedProductAppsActiveProductFragment\n}\n\nfragment useConnectedProductAppsActiveProductFragment on Product {\n  connectedApps: productApps(includeNested: true) {\n    edges {\n      node {\n        id\n        kind\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cd6087a54aea2432cbf4420daedf7fc";

export default node;
