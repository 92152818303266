import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoInput, DiscoSwitch, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
interface ExperienceSettingsCapacityFieldProps extends TestIDProps {
  form: FormStore<ExperienceSettingsFormState, ExperienceSettingsFormMutation>
}

function ExperienceSettingsCapacityField({ form }: ExperienceSettingsCapacityFieldProps) {
  const labels = useLabels()

  const classes = useStyles()

  return (
    <DiscoFormControl
      error={Boolean(form.errorsByField.capacity)}
      errorMessages={form.errorsByField.capacity}
    >
      <DiscoSwitch
        testid={"ExperienceSettingsAvailabilityFormFields.capacity.switch"}
        checked={form.state.showCapacity}
        onChange={handleCapacityToggle}
        label={
          <ExperienceSettingsLabel
            title={"Capacity"}
            sectionId={"experience_capacity"}
            tooltipContent={`Set a capacity limit on your ${labels.experience.singular}. Once capacity is reached no new registrations will be allowed. Note that ${labels.product_admin.plural} count towards the total capacity of the ${labels.experience.singular}.`}
          />
        }
        sublabel={
          <DiscoText
            variant={"body-sm"}
            marginBottom={1}
          >{`Limit availability for ${labels.experience.singular} to maxiumum number of ${labels.organization_member.plural}.`}</DiscoText>
        }
      />
      {form.state.showCapacity && (
        <div className={classes.capacity}>
          <DiscoInput
            data-testid={"ExperienceSettingsAvailabilityFormFields.capacity"}
            value={form.state.capacity}
            onChange={handleCapacityChange}
            disabled={!form.state.showCapacity}
            fullWidth
          />
        </div>
      )}
    </DiscoFormControl>
  )

  function handleCapacityToggle() {
    form.state.showCapacity = !form.state.showCapacity
  }

  function handleCapacityChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.capacity = Number(event.target.value)
  }
}

const useStyles = makeUseStyles((theme) => ({
  capacity: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
  },
}))

export default observer(ExperienceSettingsCapacityField)
