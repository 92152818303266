import { ProductStatus } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"

const PRODUCT_STATUS_LABELS: Record<ProductStatus, string> = {
  published: "Public",
  draft: "Draft",
  archived: "Archived",
  unlisted: "Unlisted",
  "%future added value": "Unknown",
}

export { PRODUCT_STATUS_LABELS }
