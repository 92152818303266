import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import {
  EditExperienceInput,
  ExperienceSettingsFormMutation,
} from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoText,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"

interface ExperienceSettingsAdditionalDetailsProps extends TestIDProps {
  form: FormStore<EditExperienceInput, ExperienceSettingsFormMutation>
}

function ExperienceSettingsAdditionalDetails({
  form,
}: ExperienceSettingsAdditionalDetailsProps) {
  const classes = useStyles()
  const [showAdvanced, setShowAdvanced] = useState(false)
  const experienceLabel = useLabel("admin_experience")
  const copyToClipboard = useCopyToClipboard()
  const theme = useTheme()
  const productId = Relay.fromGlobalId(form.state.id).id

  return (
    <>
      <DiscoContainerButton
        className={classes.headerContainer}
        testid={"ExperienceSettingsAdditionalDetails.additional-information"}
        onClick={() => setShowAdvanced(!showAdvanced)}
      >
        <DiscoIcon
          icon={"iconsax.bold/arrow-down"}
          height={24}
          width={24}
          rotate={showAdvanced ? undefined : "-90"}
          color={theme.palette.text.primary}
        />
        <div className={classes.headerText}>
          <span className={classes.titleContainer}>
            <DiscoText variant={"body-md-600"}>{"Additional Information"}</DiscoText>
          </span>
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {`Additional information such as the ${experienceLabel.singular} internal ID (can be used with the Disco-Zapier integration).`}
          </DiscoText>
        </div>
      </DiscoContainerButton>
      {showAdvanced && (
        <DiscoFormControl
          label={
            <ExperienceSettingsLabel
              title={`${experienceLabel.singular} ID`}
              tooltipContent={`The internal Disco ID for this ${experienceLabel.singular}. This ID is unique to this ${experienceLabel.singular} and can be used with the Disco-Zapier integration.`}
              sectionId={"additional-information"}
            />
          }
          testid={`ExperienceSettingsAdditionalDetails.ProductId`}
          className={classes.productIdContainer}
        >
          <DiscoInput
            disabled={true}
            value={productId}
            fullWidth
            classes={{ input: classes.input, root: classes.inputRoot }}
            endAdornment={
              <DiscoIconButton
                style={{ padding: 0 }}
                onClick={() => copyToClipboard(productId)}
              >
                <DiscoIcon icon={"copy"} />
              </DiscoIconButton>
            }
          />
        </DiscoFormControl>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  productIdContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
  headerText: {
    width: "100%",
  },
  inputRoot: {
    flexWrap: "wrap",
    height: "100%",
  },
  input: {
    flex: "1 0 fit-content",
  },
}))

export default ExperienceSettingsAdditionalDetails
