import { useLogout } from "@/authentication/logout/util/useLogout"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel, useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MembershipPlanSelectButton from "@/membership-plan/register/MembershipPlanSelectButton"
import MembershipPlanSelectSection from "@/membership-plan/register/MembershipPlanSelectSection"
import { ProductRegistrationSectionFragment$key } from "@/product/register/__generated__/ProductRegistrationSectionFragment.graphql"
import { ProductRegistrationSectionOccurrenceFragment$key } from "@/product/register/__generated__/ProductRegistrationSectionOccurrenceFragment.graphql"
import { ProductRegistrationSectionQuery } from "@/product/register/__generated__/ProductRegistrationSectionQuery.graphql"
import { ProductRegistrationType } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoIcon,
  DiscoText,
  DiscoTextButton,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { useEffect, useState } from "react"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { generatePath, useRouteMatch } from "react-router-dom"
import { graphql } from "relay-runtime"
import CancelMembershipPlanButton from "../../membership-plan/cancel/button/CancelMembershipPlanButton"

export type RegistrationStatus =
  | "anonymous"
  | "unregistered"
  | "registered"
  | "applied"
  | "waitlist"
  | "unavailableOnPlan"
  | "alreadyOnPlan"

interface Props extends TestIDProps {
  experienceKey: ProductRegistrationSectionFragment$key
  occurrenceKey?: ProductRegistrationSectionOccurrenceFragment$key | null
  setStartRegistration: React.Dispatch<React.SetStateAction<boolean>>
  onPage?: boolean
}

function ProductRegistrationSection({
  experienceKey,
  occurrenceKey,
  setStartRegistration,
  onPage = false,
  testid = `ProductRegistrationSection`,
}: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const match = useRouteMatch<{ productSlug?: string; occurrenceId?: string }>()
  const drawer = useGlobalDrawer("registration")
  const { drawerRegistrationOccurrenceId } = drawer.params
  const experienceLabel = useLabel("experience")
  const isMobile = useIsMobile()
  const isMobileDrawer = !onPage && isMobile
  const isSwitchingPlans = Boolean(
    activeOrganization?.viewerMembershipPlan && activeOrganization?.viewerMembership
  )

  const { membership } = useLazyLoadQuery<ProductRegistrationSectionQuery>(
    graphql`
      query ProductRegistrationSectionQuery($id: ID!) {
        membership: node(id: $id) {
          ... on OrganizationMembership {
            id
            email
            member {
              id
              fullName
              ...ProfileAvatarWithDetailsFragment
            }
            productMemberships(productType: "membership_plan") {
              totalCount
              edges {
                node {
                  id
                  product {
                    name
                    isFreeMembership
                    ...CancelMembershipPlanButtonFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.viewerMembership?.id || "",
    },
    { fetchPolicy: "network-only" }
  )

  const product = useFragment<ProductRegistrationSectionFragment$key>(
    graphql`
      fragment ProductRegistrationSectionFragment on Product {
        id
        status
        registrationType
        registrationAvailability
        landingPageUrl
        slug
        type
        waitlistUrl
        waitlistCtaLabel
        viewerApplication {
          id
        }
        applicationQuestions {
          totalCount
        }
        organization {
          questions {
            totalCount
          }
        }
        registrationPricing {
          basePrice
        }
        ...usePermissionsFragment
      }
    `,
    experienceKey
  )
  const permissions = usePermissions(product)
  const labels = useLabels()

  const occurrence = useFragment<ProductRegistrationSectionOccurrenceFragment$key>(
    graphql`
      fragment ProductRegistrationSectionOccurrenceFragment on Occurrence {
        id
        atCapacity
      }
    `,
    occurrenceKey || null
  )

  const occurrenceId =
    occurrence?.id || match.params.occurrenceId || drawerRegistrationOccurrenceId

  const atCapacity = occurrence && occurrence.atCapacity
  const isCommunityAdmin = permissions.has("registration.manage")
  const hasApplication = Boolean(product.applicationQuestions.totalCount)

  const [registrationStatus, setRegistrationStatus] = useState<RegistrationStatus>(
    getRegistrationStatus()
  )

  const planMemberships = Relay.connectionToArray(membership?.productMemberships)
  const currentPlan = planMemberships.length ? planMemberships[0].product : null

  const logout = useLogout()

  useEffect(() => {
    setRegistrationStatus(getRegistrationStatus())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, activeProduct, activeOrganization])

  if (!product) return null

  return (
    <div data-testid={"ProductRegistration.container"}>
      {renderRegistration()}
      {renderAdminButton()}
    </div>
  )

  function getRegistrationStatus(): RegistrationStatus {
    if (product.type === "community_event" && activeProduct?.viewerMembership?.id)
      return "registered"
    if (product.type === "community_event" && activeOrganization?.viewerMembership?.id)
      return "unregistered"
    if (product.type === "community_event" && !activeOrganization?.viewerMembership?.id)
      return "anonymous"
    if (!product.registrationPricing && !isCommunityAdmin) return "unavailableOnPlan"
    if (activeProduct?.viewerMembership?.id && product?.type !== "membership_plan")
      return "registered"
    if (product.registrationType === "waitlist" && product.waitlistUrl) return "waitlist"
    if (product.viewerApplication?.id) return "applied"
    if (
      membership?.productMemberships?.totalCount &&
      activeProduct?.type === "membership_plan"
    )
      return "alreadyOnPlan"
    if (activeOrganization?.viewerMembership?.id) return "unregistered"
    return "anonymous"
  }

  function renderAdminButton() {
    if (!permissions.has("registration.manage")) return null
    if (registrationStatus === "registered") return null
    if (product.type === "membership_plan") return null
    return (
      <DiscoTooltip
        content={`As a Community Admin, you can view the ${
          occurrenceId ? "Event" : experienceLabel.singular
        } without having to register.`}
      >
        <DiscoButton
          color={"grey"}
          variant={"outlined"}
          to={{
            pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
              productSlug: product.slug,
            }),
            ...(occurrenceId && {
              search: setSearchParams<GlobalDrawerParams<"event">>("", {
                drawerOccurrenceId: occurrenceId,
                drawerEventTab: "details",
              }),
            }),
          }}
          width={"100%"}
          className={classes.adminViewButton}
          data-testid={`${testid}.admin-view-link`}
          // Needs to be _top or else the page will infinitely re-render
          // top targets the outter-most frame aka: window, see https://stackoverflow.com/a/63759718
          target={drawer.isOpen ? "_self" : "_top"}
        >
          {occurrenceId ? "View Event" : `View ${experienceLabel.singular}`}
        </DiscoButton>
      </DiscoTooltip>
    )
  }

  function renderRegistration() {
    switch (registrationStatus) {
      case "anonymous":
      default:
        return (
          <>
            <DiscoText variant={"body-lg-600"}>{"Registration"}</DiscoText>
            <DiscoText color={"text.secondary"} paddingBottom={2}>
              {getRegistrationTypeText(product.registrationType)}
            </DiscoText>
            <DiscoButton
              data-testid={`${testid}.register-now`}
              onClick={handleStartRegistration}
              width={"100%"}
            >
              {"Register Now"}
            </DiscoButton>
            {product.landingPageUrl && (
              <DiscoButton
                to={product.landingPageUrl!}
                width={"100%"}
                color={"grey"}
                variant={"outlined"}
                className={classes.landingPage}
                data-testid={`${testid}.landing-page-link`}
              >
                {"Visit Landing Page"}
              </DiscoButton>
            )}
          </>
        )
      case "unregistered": {
        const RegistrationButton = ({ disabled }: { disabled?: boolean }) =>
          atCapacity ? (
            <DiscoButton disabled color={"grey"} variant={"outlined"} width={"100%"}>
              {"At Capacity"}
            </DiscoButton>
          ) : (
            <DiscoButton
              disabled={disabled}
              onClick={handleStartRegistration}
              width={"100%"}
              data-testid={`${testid}.register-now`}
            >
              {hasApplication
                ? "Apply Now"
                : product.type === "membership_plan"
                ? isSwitchingPlans
                  ? "Switch Plan"
                  : "Register Now"
                : "Register Now"}
            </DiscoButton>
          )
        return (
          <>
            {renderUserDetails(
              occurrenceId
                ? "Register for Event as:"
                : product.type === "membership_plan"
                ? isSwitchingPlans
                  ? `Switch to plan as`
                  : `Register for plan as:`
                : `Register for ${experienceLabel.singular} as:`
            )}

            {!isMobileDrawer && (
              <DiscoText variant={"body-sm"} color={"text.secondary"} paddingBottom={2}>
                {"or"}
                <DiscoTextButton
                  onClick={logout}
                  textVariant={"body-sm"}
                  className={classes.signOut}
                  color={theme.palette.primary.main}
                  testid={`${testid}.signout`}
                >
                  {"Sign Out"}
                </DiscoTextButton>
                {"to register under a different account."}
              </DiscoText>
            )}

            {isCommunityAdmin ? (
              <DiscoTooltip
                content={
                  product.type === "membership_plan"
                    ? "Admin cannot select membership plans."
                    : `Admins can view ${labels.admin_experience.plural} or add themselves as a ${labels.product_admin.singular} or ${labels.product_instructor.singular}.`
                }
              >
                <span>
                  <RegistrationButton disabled />
                </span>
              </DiscoTooltip>
            ) : (
              <RegistrationButton />
            )}
          </>
        )
      }
      case "alreadyOnPlan":
        if (!currentPlan) return null
        return (
          <>
            <div className={classes.currentPlan}>
              <DiscoText color={"text.secondary"} paddingBottom={2} display={"inline"}>
                {`Current plan: `}
              </DiscoText>
              <DiscoText paddingBottom={2} display={"inline"} variant={"body-md-700"}>
                {currentPlan?.name}
              </DiscoText>
            </div>

            {membership && membership.member && (
              <div className={classes.avatar}>
                <ProfileAvatarWithDetails
                  userKey={membership.member}
                  details={membership.email}
                />
              </div>
            )}

            <div className={classes.planButtons}>
              {/* Upgrade from the free plan */}
              <MembershipPlanSelectButton>
                {(buttonProps) => (
                  <DiscoButton
                    {...buttonProps}
                    color={"grey"}
                    variant={"outlined"}
                    width={"100%"}
                    onClick={handleStartRegistration}
                    testid={"ProductRegistrationSection.change-plan"}
                  >
                    {"Change Plan"}
                  </DiscoButton>
                )}
              </MembershipPlanSelectButton>

              <CancelMembershipPlanButton membershipPlanKey={currentPlan}>
                {(buttonProps) => (
                  <DiscoButton {...buttonProps} color={"error"} width={"100%"}>
                    {"Cancel Plan"}
                  </DiscoButton>
                )}
              </CancelMembershipPlanButton>
            </div>
          </>
        )
      case "registered":
        return (
          <>
            {renderUserDetails("You are registered as:")}

            {/* If the product being viewed is a membership plan */}
            {activeProduct?.type === "membership_plan" ||
            product?.type === "membership_plan" ? (
              <div className={classes.planButtons}>
                <MembershipPlanSelectButton>
                  {(buttonProps) => (
                    <DiscoButton
                      {...buttonProps}
                      color={"grey"}
                      variant={"outlined"}
                      width={"100%"}
                      testid={"ProductRegistrationSection.change-plan"}
                    >
                      {"Change Plan"}
                    </DiscoButton>
                  )}
                </MembershipPlanSelectButton>

                <CancelMembershipPlanButton membershipPlanKey={currentPlan!}>
                  {(buttonProps) => (
                    <DiscoButton {...buttonProps} color={"error"} width={"100%"}>
                      {"Cancel Plan"}
                    </DiscoButton>
                  )}
                </CancelMembershipPlanButton>
              </div>
            ) : (
              <DiscoButton
                to={{
                  pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                    productSlug: product.slug,
                  }),
                  ...(occurrenceId && {
                    search: setSearchParams<GlobalDrawerParams<"event">>("", {
                      drawerOccurrenceId: occurrenceId,
                      drawerEventTab: "details",
                    }),
                  }),
                }}
                width={"100%"}
                data-testid={`${testid}.overview-link`}
                // Needs to be _top or else the page will infinitely re-render
                // top targets the outter-most frame aka: window, see https://stackoverflow.com/a/63759718
                target={drawer.isOpen ? "_self" : "_top"}
              >
                {occurrenceId ? "View Event" : `View ${experienceLabel.singular}`}
              </DiscoButton>
            )}
          </>
        )
      case "applied":
        return (
          <>
            {renderUserDetails(`Applied for ${experienceLabel.singular} as:`)}

            {!isMobileDrawer && (
              <DiscoText variant={"body-sm"} color={"text.secondary"} paddingBottom={2}>
                {"or"}
                <DiscoTextButton
                  onClick={logout}
                  textVariant={"body-sm"}
                  className={classes.signOut}
                  color={theme.palette.primary.main}
                >
                  {"Sign Out"}
                </DiscoTextButton>
                {"to apply under a different account."}
              </DiscoText>
            )}

            <DiscoButton
              leftIcon={<DiscoIcon icon={"time"} />}
              color={"warning"}
              width={"100%"}
              data-testid={`${testid}.under-review`}
            >
              {"Under Review"}
            </DiscoButton>
          </>
        )
      case "waitlist":
        return (
          <DiscoButton
            to={product.waitlistUrl!}
            target={"_blank"}
            width={"100%"}
            data-testid={`${testid}.join-the-waitlist`}
          >
            {product.waitlistCtaLabel || "Join Waitlist"}
          </DiscoButton>
        )
      case "unavailableOnPlan":
        if (product.type === "community_event") return null
        return <MembershipPlanSelectSection className={classes.selectPlan} />
    }
  }

  function renderUserDetails(message: string) {
    return (
      <>
        <DiscoText
          variant={"body-sm"}
          color={"text.secondary"}
          paddingBottom={2}
          testid={"ProductRegistrationSection.message"}
        >
          {message}
          {isMobileDrawer && (
            <DiscoText component={"span"} variant={"body-sm-600"}>
              {` ${membership?.member?.fullName}`}
            </DiscoText>
          )}
        </DiscoText>

        {!isMobileDrawer && membership && membership.member && (
          <div className={classes.avatar}>
            <ProfileAvatarWithDetails
              userKey={membership.member}
              details={membership.email}
            />
          </div>
        )}
      </>
    )
  }

  function getRegistrationTypeText(type: ProductRegistrationType) {
    switch (type) {
      case "closed":
        return "Registration is closed"
      case "waitlist":
        return "Join the waitlist"
      case "open":
      case "application":
      default:
        return "Open for new registrations"
    }
  }

  function handleStartRegistration() {
    setStartRegistration(true)
  }
}

const useStyles = makeUseStyles((theme) => ({
  landingPage: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  signOut: {
    marginBottom: theme.spacing(0.25),
  },
  adminViewButton: {
    marginTop: theme.spacing(1),
  },
  planButtons: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  currentPlan: {
    paddingBottom: theme.spacing(2),
  },
  selectPlan: {
    border: "none",
    padding: 0,
    marginTop: 0,
  },
}))

export const ProductRegistrationSectionSkeleton: React.FC = () => {
  return (
    <>
      <DiscoTextSkeleton width={"75%"} height={"40px"} />
      <DiscoTextSkeleton marginBottom={1} width={"100%"} />
      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: ProductRegistrationSection,
  skeleton: ProductRegistrationSectionSkeleton,
})
