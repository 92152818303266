/**
 * @generated SignedSource<<f7593d5de8fbe19a2cc9b1da243251e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GiftProductFormPaymentStepFragment$data = {
  readonly registrationPricing: {
    readonly basePrice: number;
  } | null;
  readonly " $fragmentType": "GiftProductFormPaymentStepFragment";
};
export type GiftProductFormPaymentStepFragment$key = {
  readonly " $data"?: GiftProductFormPaymentStepFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GiftProductFormPaymentStepFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GiftProductFormPaymentStepFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RegistrationPricing",
      "kind": "LinkedField",
      "name": "registrationPricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "6489ea9acfd5bd22f5afba988ff02cb5";

export default node;
