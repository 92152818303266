import AddAppButton from "@/apps/add-app-button/AddAppButton"
import NavFolderSettingsButton from "@/apps/add-nav-folder-button/NavFolderSettingsButton"
import { AppLevelProvider } from "@/apps/util/appLevelContext"
import { ProductAddAppDropdownContentQuery } from "@/product/sidebar/__generated__/ProductAddAppDropdownContentQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { graphql, useLazyLoadQuery } from "react-relay"

type Props = {
  productId: GlobalID
}

function ProductAddAppDropdownContent({ productId }: Props) {
  // Add an AppLevelProvider wrapper here when the to avoid needing to query
  // appLevelContextFragment on every product in the sidebar (which is expensive)
  const { product } = useLazyLoadQuery<ProductAddAppDropdownContentQuery>(
    graphql`
      query ProductAddAppDropdownContentQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            ...appLevelContextFragment
          }
        }
      }
    `,
    { id: productId }
  )

  return (
    <AppLevelProvider productKey={product}>
      <AddAppButton>
        {({ onClick }) => {
          return (
            <DiscoDropdownItem
              testid={"ProductAddAppButton.add-app"}
              title={"Add App"}
              onClick={onClick}
            />
          )
        }}
      </AddAppButton>
      <NavFolderSettingsButton>
        {({ onClick }) => {
          return (
            <DiscoDropdownItem
              testid={"ProductAddAppButton.add-folder"}
              title={"Add Folder"}
              onClick={onClick}
            />
          )
        }}
      </NavFolderSettingsButton>
    </AppLevelProvider>
  )
}

function Skeleton() {
  return (
    <>
      <DiscoButtonSkeleton />
      <DiscoButtonSkeleton width={"85px"} />
    </>
  )
}

export default Relay.withSkeleton({
  component: ProductAddAppDropdownContent,
  skeleton: Skeleton,
})
