/**
 * @generated SignedSource<<6b9d73f6b0a725c8b25ea63ade0d51bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductRegistrationAvailability = "public" | "private" | "hidden" | "%future added value";
export type ProductRegistrationType = "closed" | "open" | "application" | "waitlist" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductRegistrationSectionFragment$data = {
  readonly id: string;
  readonly status: ProductStatus;
  readonly registrationType: ProductRegistrationType;
  readonly registrationAvailability: ProductRegistrationAvailability;
  readonly landingPageUrl: string | null;
  readonly slug: string;
  readonly type: ProductType;
  readonly waitlistUrl: string | null;
  readonly waitlistCtaLabel: string | null;
  readonly viewerApplication: {
    readonly id: string;
  } | null;
  readonly applicationQuestions: {
    readonly totalCount: number;
  };
  readonly organization: {
    readonly questions: {
      readonly totalCount: number;
    };
  };
  readonly registrationPricing: {
    readonly basePrice: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "ProductRegistrationSectionFragment";
};
export type ProductRegistrationSectionFragment$key = {
  readonly " $data"?: ProductRegistrationSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductRegistrationSectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductRegistrationSectionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "landingPageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitlistUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waitlistCtaLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApplication",
      "kind": "LinkedField",
      "name": "viewerApplication",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionNodeConnection",
      "kind": "LinkedField",
      "name": "applicationQuestions",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionNodeConnection",
          "kind": "LinkedField",
          "name": "questions",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RegistrationPricing",
      "kind": "LinkedField",
      "name": "registrationPricing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "39eb4c2689a74f5c4824800e368d536b";

export default node;
