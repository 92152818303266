import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import { GiftProductFormStore } from "@/product/gift/GiftProductForm"
import Relay from "@/relay/relayUtils"
import {
  DiscoCheckbox,
  DiscoCheckboxSkeleton,
  DiscoFormControl,
  DiscoLink,
  DiscoText,
} from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  /** `store` from `CheckoutStore`, or `form from `GiftProductFormStore` */
  store?: Partial<CheckoutStore>
  giftStore?: Partial<GiftProductFormStore>
  hideTerms?: boolean
  showErrorMessage?: boolean
  setShowErrorMessage?: React.Dispatch<React.SetStateAction<boolean>>
}

function ProductRegistrationCheckboxes({
  store,
  giftStore,
  hideTerms = false,
  testid = "ProductRegistrationCheckboxes",
  showErrorMessage,
  setShowErrorMessage,
}: Props) {
  const activeOrganization = useActiveOrganization()!

  // If the user is already in the community, skip the terms
  if (activeOrganization?.viewerMembership?.id) {
    if (store) store.isForNewUser = false
    else if (giftStore?.state) giftStore.state.gifter.isNewUser = false
    return null
  }

  return (
    <>
      {/* Accept terms */}
      {!hideTerms && (
        <DiscoFormControl
          errorMessages={
            showErrorMessage
              ? ["Please accept the terms and conditions before continuing."]
              : undefined
          }
          error={showErrorMessage}
        >
          <DiscoCheckbox
            name={"user-accepts-terms-and-privacy"}
            checked={Boolean(
              store
                ? store.hasAcceptedTerms
                : giftStore
                ? giftStore.state?.hasAcceptedTerms
                : false
            )}
            onChange={(v) => handleToggleTerms(v)}
            data-testid={`${testid}.terms-checkbox`}
            label={
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"I agree to the Disco "}
                <DiscoLink to={"https://www.disco.co/terms"} target={"_blank"}>
                  <DiscoText
                    variant={"body-sm"}
                    color={"primary.main"}
                    component={"span"}
                  >
                    {"Terms and Conditions"}
                  </DiscoText>
                </DiscoLink>
                {" and "}
                <DiscoLink to={"https://www.disco.co/privacy-policy"} target={"_blank"}>
                  <DiscoText
                    variant={"body-sm"}
                    color={"primary.main"}
                    component={"span"}
                  >
                    {"Privacy Policy."}
                  </DiscoText>
                </DiscoLink>
              </DiscoText>
            }
          />
        </DiscoFormControl>
      )}
    </>
  )

  function handleToggleTerms(v: boolean) {
    if (store) store.hasAcceptedTerms = v
    else if (giftStore?.state) giftStore.state.hasAcceptedTerms = v
    if (setShowErrorMessage && v) setShowErrorMessage(false)
  }
}

function ProductRegistrationCheckboxesSkeleton() {
  return (
    <>
      <DiscoFormControl>
        <DiscoCheckboxSkeleton />
      </DiscoFormControl>
      <DiscoFormControl>
        <DiscoCheckboxSkeleton />
      </DiscoFormControl>
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(ProductRegistrationCheckboxes),
  skeleton: ProductRegistrationCheckboxesSkeleton,
})
