/**
 * @generated SignedSource<<57377897a84975c5b6faa425db2b3721>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MembershipPlanExperiencePricingListItemFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly cover: string;
  readonly membershipBenefits: {
    readonly totalCount: number;
  } | null;
  readonly pricing: {
    readonly id: string;
    readonly amountCents: number;
    readonly frequency: PricingFrequency | null;
    readonly kind: PricingKind;
  } | null;
  readonly " $fragmentType": "MembershipPlanExperiencePricingListItemFragment";
};
export type MembershipPlanExperiencePricingListItemFragment$key = {
  readonly " $data"?: MembershipPlanExperiencePricingListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MembershipPlanExperiencePricingListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipPlanExperiencePricingListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipBenefitNodeConnection",
      "kind": "LinkedField",
      "name": "membershipBenefits",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Pricing",
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountCents",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frequency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "6a951dd82198f55a48460005ff31ae6b";

export default node;
