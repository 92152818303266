import { OrganizationRole } from "@/admin/members/roles/__generated__/RoleAvatarStackQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import {
  useOrganizationRoleDescription,
  useOrganizationRoleLabel,
} from "@/role/roleUtils"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & {
  organizationRole: OrganizationRole
}

export default function OrganizationRoleTag(props: Props) {
  const { organizationRole, testid = "OrganizationRoleTag" } = props
  const roleLabel = useOrganizationRoleLabel(organizationRole)
  const theme = useTheme()
  const description = useOrganizationRoleDescription(organizationRole)
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()

  return (
    <DiscoTag
      testid={`${testid}.${organizationRole}`}
      name={roleLabel.singular}
      backgroundColor={getBackgroundColor()}
      color={getColor()}
      tooltipContent={getDescription()}
    />
  )

  function getBackgroundColor() {
    const intensity = theme.palette.type === "dark" ? 600 : 100
    switch (organizationRole) {
      case "owner":
        return theme.palette.groovy.blue[intensity]
      case "admin":
        return theme.palette.groovy.cyan[intensity]
      case "member":
      default:
        return theme.palette.groovy.purple[intensity]
    }
  }

  function getColor() {
    if (organizationRole === "owner" && theme.palette.type !== "dark")
      return theme.palette.groovy.blue[400]
    return theme.palette.text.primary
  }

  function getDescription() {
    // Use the custom description if set
    if (roleLabel.description) return roleLabel.description

    // Only show the permissions description to people with any role > member
    if (
      (activeProduct?.viewerRole && activeProduct.viewerRole !== "member") ||
      (activeOrganization?.viewerRole && activeOrganization.viewerRole !== "member")
    ) {
      return description
    }
    return `Community ${roleLabel.singular}`
  }
}
