import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { EditEventDrawerFormStore } from "@/organization/occurrence/event-drawer/EditEventDrawerContext"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import {
  EditExperienceInput,
  ExperienceSettingsFormMutation,
  ProductStatus,
} from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import Relay from "@/relay/relayUtils"
import { DiscoFormControl } from "@disco-ui"
import DiscoRadioBox from "@disco-ui/radio/DiscoRadioBox"
import { RadioGroup, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  form:
    | FormStore<EditExperienceInput, ExperienceSettingsFormMutation>
    | EditEventDrawerFormStore
}
function ExperienceSettingsStatusRadioGroup({
  form,
  testid = "ExperienceSettingsStatusRadioGroup",
}: Props) {
  const labels = useLabels()
  const theme = useTheme()

  return (
    <DiscoFormControl
      label={
        <ExperienceSettingsLabel
          title={"Status"}
          sectionId={"experience_status"}
          tooltipContent={`In draft mode, only ${labels.admin_member.plural} are able to see your ${labels.admin_experience.singular}. When published, ${labels.admin_member.plural} may register and join.`}
        />
      }
      sublabel={`Choose to publish for ${labels.admin_member.plural} to see or draft to continue editing before it's visible.`}
      error={Boolean(form.errorsByField.status)}
      errorMessages={form.errorsByField.status}
    >
      <RadioGroup
        onChange={handleStatusChange}
        value={form.state.status}
        row
        style={{ gap: theme.spacing(1.5) }}
      >
        <DiscoRadioBox
          icon={"feather"}
          value={"draft"}
          title={"Draft"}
          testid={`${testid}.radio.draft`}
          subtitle={`Continue editing worry free. Drafts are only visible to ${labels.product_instructor.plural}, ${labels.product_admin.plural}, ${labels.organization_admin.plural} and ${labels.organization_owner.plural}.`}
        />
        <DiscoRadioBox
          icon={"rocket"}
          value={"published"}
          title={"Published"}
          testid={`${testid}.radio.publish`}
          subtitle={`Launch ${labels.admin_experience.singular} live and allow to be discovered and registered for.`}
        />
      </RadioGroup>
    </DiscoFormControl>
  )

  function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.status = event.target.value as ProductStatus
  }
}

export default Relay.withSkeleton({
  component: observer(ExperienceSettingsStatusRadioGroup),
  skeleton: () => null,
})
