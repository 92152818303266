/**
 * @generated SignedSource<<7e210260ba43b3d584b512b22dc737a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannelSideBarItemFragment$data = {
  readonly id: string;
  readonly customAppTitle: string | null;
  readonly chatChannel: {
    readonly id: string;
    readonly externalChannelId: string;
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  } | null;
  readonly product: {
    readonly slug: string;
    readonly name: string;
    readonly viewerMembership: {
      readonly id: string;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelBadgeFragment" | "ChatChannelSettingsModalButtonFragment" | "DeleteChatChannelAppButtonFragment">;
  readonly " $fragmentType": "ChatChannelSideBarItemFragment";
};
export type ChatChannelSideBarItemFragment$key = {
  readonly " $data"?: ChatChannelSideBarItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelSideBarItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelSideBarItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatChannel",
      "kind": "LinkedField",
      "name": "chatChannel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalChannelId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "usePermissionsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductMembership",
          "kind": "LinkedField",
          "name": "viewerMembership",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelBadgeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelSettingsModalButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteChatChannelAppButtonFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "de8455ed02f7c7e643c064a0a766ab0d";

export default node;
