import CardForm from "@/payment/card/CardForm"
import PaymentFormDiscountSection from "@/product/checkout/form/discount/PaymentFormDiscountSection"
import { GiftProductFormStore } from "@/product/gift/GiftProductForm"
import { GiftProductFormPaymentStepFragment$key } from "@/product/gift/payment/__generated__/GiftProductFormPaymentStepFragment.graphql"
import { DiscoButton, DiscoDivider, DiscoText } from "@disco-ui"
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  form: GiftProductFormStore
  productKey: GiftProductFormPaymentStepFragment$key
}

const GiftProductFormPaymentStep = observer<Props>((props) => {
  const { form, productKey } = props

  // Set the drawer to full screen
  const drawerContext = useDrawerContext()
  const drawer = useGlobalDrawer("registration")
  if (drawerContext.setFullScreen) drawerContext.setFullScreen(true)

  const product = useFragment<GiftProductFormPaymentStepFragment$key>(
    graphql`
      fragment GiftProductFormPaymentStepFragment on Product {
        registrationPricing {
          basePrice
        }
      }
    `,
    productKey
  )

  const stripe = useStripe()
  const elements = useElements()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const classes = useStyles()

  if (!product.registrationPricing) return null

  return (
    <form onSubmit={handleSubmit}>
      <DiscoText variant={"heading-md"}>{"Payment"}</DiscoText>
      <DiscoDivider marginTop={3} marginBottom={3} />
      <CardForm
        customClassName={classes.form}
        onCardHolderNameChange={(e) =>
          (form.state.payment.cardHolderName = e.currentTarget.value)
        }
        onPostalCodeChange={(e) =>
          (form.state.payment.postalCode = e.currentTarget.value)
        }
        cardFormState={{
          cardHolderName: form.state.payment.cardHolderName,
          postalCode: form.state.payment.postalCode,
          isSaveCardCheckboxSelected: false,
        }}
      />
      <DiscoDivider marginTop={3} marginBottom={3} />

      {/* Future: store a discountCode in the form and allow input */}
      <PaymentFormDiscountSection
        testid={"GiftProductFormPaymentStep.price"}
        basePrice={product.registrationPricing.basePrice}
        finalPrice={product.registrationPricing.basePrice}
        discountKey={null}
      />

      <DiscoDivider marginTop={3} marginBottom={3} />

      <div className={classes.buttons}>
        <DiscoButton
          color={"grey"}
          variant={"outlined"}
          onClick={handleBack}
          width={"100%"}
        >
          {"Back"}
        </DiscoButton>
        <DiscoButton type={"submit"} shouldDisplaySpinner={isSubmitting} width={"100%"}>
          {"Complete Purchase"}
        </DiscoButton>
      </div>
    </form>
  )

  function handleBack() {
    form.state.step = "registration"
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    try {
      if (!stripe || !elements)
        throw new Error("Unexpected error processing payment. Please try again later.")

      setIsSubmitting(true)

      const stripeResponse = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          name: form.state.payment.cardHolderName,
          address: {
            postal_code: form.state.payment.cardHolderName,
          },
        },
      })
      if (stripeResponse.error)
        throw new Error("You must provide a valid credit card for payment.")

      const { didSave } = await form.submit({
        productId: form.state.productId,
        recipientEmail: form.state.recipientEmail,
        message: form.state.message,
        paymentMethodId: stripeResponse.paymentMethod.id,
      })
      if (didSave) {
        // Go to success page.
        form.state.step = "success"

        // Set the drawer params if available
        if (drawer.isOpen) drawer.setParams({ registrationStep: "success" })
      } else {
        // If needed, push back to first step of the form for validation.
        const hasRegistrationError = form.errors.some((err) =>
          REGISTRATION_STEP_ERROR_FIELDS.has(err.field)
        )
        if (hasRegistrationError) {
          form.state.step = "registration"
        }
      }
    } catch (err) {
      displayErrorToast(err)
    } finally {
      setIsSubmitting(false)
    }
  }
})

/** Error fields that correspond to a field on the "registration" step */
const REGISTRATION_STEP_ERROR_FIELDS = new Set(["message", "recipientEmail", "user"])

import { useDrawerContext } from "@/core/context/DrawerContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { displayErrorToast } from "@components/toast/ToastProvider"
const useStyles = makeUseStyles((theme) => ({
  form: {
    width: "100%",
    maxWidth: "unset !important",
  },
  buttons: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default GiftProductFormPaymentStep
