import { useSignupUser } from "@/authentication/signup/util/useSignupUser"
import { AuthUserData, useAuthUser } from "@/core/context/AuthUserContext"
import { useDrawerContext } from "@/core/context/DrawerContext"
import CheckoutFormApplicationSection from "@/product/checkout/form/application/CheckoutFormApplicationSection"
import CheckoutFormOrganizationFormSection from "@/product/checkout/form/organization-form/CheckoutFormOrganizationFormSection"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import useCompleteCheckoutMutation from "@/product/checkout/store/useCompleteCheckoutMutation"
import useUpdateCheckoutMutation from "@/product/checkout/store/useUpdateCheckoutMutation"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoCheckboxSkeleton,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"

interface Props extends TestIDProps {
  store: CheckoutStore
}

function ProductRegistrationApplyStep({ store }: Props) {
  const drawer = useDrawerContext()
  const classes = useStyles()
  const updateCheckout = useUpdateCheckoutMutation(store)
  const completeCheckout = useCompleteCheckoutMutation(store)

  const signupUser = useSignupUser()
  const { authUser } = useAuthUser()
  const userTimezone = useUserTimezone()

  const [isCreatingUser, setIsCreatingUser] = useState(false)

  if (drawer.setFullScreen) drawer.setFullScreen(true)

  if (!store.checkout?.hasApplication && !store.checkout?.organizationForm) return null

  return (
    <>
      <DiscoText variant={"body-md-600"} marginBottom={2}>
        {"Application"}
      </DiscoText>

      <CheckoutFormApplicationSection store={store} />
      <CheckoutFormOrganizationFormSection store={store} />

      <div className={classes.buttons}>
        <DiscoButton
          color={"grey"}
          variant={"outlined"}
          onClick={handleBack}
          width={"100%"}
        >
          {"Back"}
        </DiscoButton>

        <DiscoButton
          type={"submit"}
          disabled={!canSubmitForm()}
          shouldDisplaySpinner={store.isSubmitting}
          onClick={handleSubmit}
          width={"100%"}
        >
          {store.requiresPaymentStep
            ? "Next Step"
            : store.checkout?.hasApplication
            ? "Submit"
            : store.isGifted
            ? "Claim this Gift"
            : "Complete Registration"}
        </DiscoButton>
      </div>
    </>
  )

  function handleBack() {
    store.currentStep = "registration"
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault()

    if (!canSubmitForm()) return

    try {
      if (authUser) {
        updateUserInStore(authUser)
      } else {
        // signup user if they are not logged in
        setIsCreatingUser(true)
        const newUser = await signupUser({
          email: store.profile.email!,
          firstName: store.profile.firstName,
          lastName: store.profile.lastName,
          timezone: userTimezone,
        })

        updateUserInStore(newUser)
      }

      const isValid = await updateCheckout()
      if (!isValid) return
      if (store.requiresPaymentStep) {
        // Paid Product
        store.currentStep = "payment"
      } else {
        // Free Product
        await completeCheckout()
      }
    } catch (err) {
      displayErrorToast(err)
    } finally {
      setIsCreatingUser(false)
    }
  }

  function updateUserInStore(user: AuthUserData<true>) {
    runInAction(() => {
      store.profile.userId = Relay.rawId(user.id)
      store.profile.email = user.email
      store.profile.firstName = user.firstName
      store.profile.lastName = user.lastName
      store.profile.timezone = user.timezone
    })
  }

  function canSubmitForm(): boolean {
    if (isCreatingUser) return false
    if (store.isSubmitting) return false
    if (!store.canCompleteRegistrationStep) return false
    return true
  }
}

const useStyles = makeUseStyles((theme) => ({
  buttons: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export const ProductRegistrationApplyStepSkeleton: React.FC<{
  store: CheckoutStore
}> = (props) => {
  const { store } = props
  const theme = useTheme()
  const classes = useStyles()
  return (
    <>
      <DiscoTextSkeleton variant={"body-md-600"} width={"45%"} />

      <DiscoTextSkeleton variant={"body-sm"} width={"80%"} />
      {store.checkout?.application && <Skeleton width={"100%"} height={"200px"} />}
      {store.checkout?.organizationForm && (
        <div style={{ paddingBottom: theme.spacing(2) }}>
          <DiscoCheckboxSkeleton />
        </div>
      )}

      <div style={{ paddingBottom: theme.spacing(2) }}>
        <DiscoCheckboxSkeleton />
      </div>

      <div className={classes.buttons}>
        <DiscoButtonSkeleton width={"100%"} />
        <DiscoButtonSkeleton width={"100%"} />
      </div>
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ProductRegistrationApplyStep),
  skeleton: ProductRegistrationApplyStepSkeleton,
})
