import ChatChannelSettingsModalButton from "@/apps/list/app/chat/button/ChatChannelSettingsModalButton"
import DeleteChatChannelAppButton from "@/apps/list/app/chat/button/DeleteChatChannelAppButton"
import { AppSidebarItemProps } from "@/apps/sidebar-item/AppSidebarItem"
import { useStreamChannel } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import { ChatChannelSideBarItemFragment$key } from "@/product/sidebar/chat/channels/__generated__/ChatChannelSideBarItemFragment.graphql"
import ChatChannelBadge from "@components/chat/badge/ChatChannelBadge"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"
import { generatePath } from "react-router-dom"

type Props = Omit<AppSidebarItemProps, "appKey"> & {
  testid: string
  appKey: ChatChannelSideBarItemFragment$key
  isChannelsSidebar?: boolean
}

const ChatChannelSideBarItem = observer(
  ({ testid, appKey, isChannelsSidebar = false, ...rest }: Props) => {
    const app = useFragment<ChatChannelSideBarItemFragment$key>(
      graphql`
        fragment ChatChannelSideBarItemFragment on ProductApp {
          id
          customAppTitle
          chatChannel {
            id
            externalChannelId
            ...usePermissionsFragment
          }
          product {
            slug
            name
            viewerMembership {
              id
            }
          }
          ...ChatChannelBadgeFragment
          ...ChatChannelSettingsModalButtonFragment
          ...DeleteChatChannelAppButtonFragment
        }
      `,
      appKey
    )
    const { chatChannel } = app
    const chatChannelName = chatChannel && app.customAppTitle ? app.customAppTitle : null
    const TEST_ID = `${testid}${
      app.product?.name ? `.${app.product.name}` : ""
    }.${chatChannelName}`

    const canManage = usePermissions(chatChannel).has("chat.manage") && !isChannelsSidebar
    const streamChannel = useStreamChannel(chatChannel?.externalChannelId)

    const to = app.chatChannel
      ? isChannelsSidebar
        ? generatePath(ROUTE_NAMES.CHAT.CHANNEL.DETAIL, {
            channelId: app.chatChannel.id,
          })
        : app.product
        ? generatePath(ROUTE_NAMES.PRODUCT.CHAT.CHANNEL, {
            productSlug: app.product.slug,
            channelId: app.chatChannel.id,
          })
        : generatePath(ROUTE_NAMES.COMMUNITY.CHAT.CHANNEL, {
            channelId: app.chatChannel.id,
          })
      : undefined

    function getIsSelected() {
      if (!to) return false
      return location.pathname === to || location.pathname.startsWith(`${to}/`)
    }

    if (!chatChannelName) return null

    return (
      <CommunitySidebarItem
        {...rest}
        testid={TEST_ID}
        to={to}
        name={chatChannelName}
        // Badge should always exist here
        leftIcon={
          <ChatChannelBadge isSideBar={true} isSelected={getIsSelected()} appKey={app} />
        }
        notificationConfig={{
          channels:
            // Don't show notifications if viewer isn't a member of the app's product
            streamChannel && (!app.product || app.product.viewerMembership)
              ? [streamChannel]
              : [],
        }}
        overflowItems={
          canManage && [
            // Edit button
            <ChatChannelSettingsModalButton key={0} appKey={app}>
              {(buttonProps) => (
                <DiscoDropdownItem
                  {...buttonProps}
                  title={"Edit"}
                  testid={`${TEST_ID}.overflow.edit`}
                />
              )}
            </ChatChannelSettingsModalButton>,
            // Delete button
            <DeleteChatChannelAppButton key={1} appKey={app}>
              {(buttonProps) => (
                <DiscoDropdownItem
                  {...buttonProps}
                  title={"Remove"}
                  testid={`${TEST_ID}.overflow.remove`}
                />
              )}
            </DeleteChatChannelAppButton>,
          ]
        }
      />
    )
  }
)

export default ChatChannelSideBarItem
