import { useLabel } from "@/core/context/LabelsContext"
import ExperienceAdminsList, {
  ExperienceAdminsListSkeleton,
  ExperienceAdminsListToolbarState,
} from "@/product/settings/ExperienceAdminsList"
import { ExperienceSettingsTeamTabFragment$key } from "@/product/settings/__generated__/ExperienceSettingsTeamTabFragment.graphql"
import Relay from "@/relay/relayUtils"
import { useProductRoleLabels } from "@/role/roleUtils"
import { DiscoButtonSkeleton, DiscoSection, DiscoText } from "@disco-ui"
import DiscoTableSearchInput from "@disco-ui/table/header/search/DiscoTableSearchInput"
import { Theme, useMediaQuery } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  experienceKey: ExperienceSettingsTeamTabFragment$key
}

function ExperienceSettingsTeamTab({ experienceKey }: Props) {
  const productLabel = useLabel("admin_experience")
  const roleLabels = useProductRoleLabels()
  const isXsDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"))
  const [toolbarState, setToolbarState] = useState<ExperienceAdminsListToolbarState>({
    search: "",
  })

  const product = useFragment<ExperienceSettingsTeamTabFragment$key>(
    graphql`
      fragment ExperienceSettingsTeamTabFragment on Product {
        id
        name
      }
    `,
    experienceKey
  )

  if (!product) return null

  return (
    <>
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={1.5}>
        {"Set "}
        <DiscoText
          variant={"body-sm-600"}
          component={"span"}
          color={"text.secondary"}
        >{`${productLabel.singular} ${roleLabels.manager.plural}`}</DiscoText>
        {" and "}
        <DiscoText
          variant={"body-sm-600"}
          component={"span"}
          color={"text.secondary"}
        >{`${productLabel.singular} ${roleLabels.instructor.plural}`}</DiscoText>
        {` inside ${product.name}.`}
      </DiscoText>

      {/* Experience Admin List */}
      <DiscoSection
        groovyDepths={isXsDown ? undefined : "insideCard"}
        padding={isXsDown ? 0 : 2}
      >
        <DiscoTableSearchInput
          testid={"ExperienceAdminsList"}
          onChange={(search) => setToolbarState({ ...toolbarState, search })}
          placeholder={"Search team"}
        />
        <ExperienceAdminsList productId={product.id} toolbarState={toolbarState} />
      </DiscoSection>
    </>
  )
}

export const ExperienceSettingsTeamTabSkeleton = () => {
  return (
    <>
      <ExperienceAdminsListSkeleton />
      <DiscoButtonSkeleton width={"100%"} />
    </>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperienceSettingsTeamTab),
  skeleton: ExperienceSettingsTeamTabSkeleton,
})
