import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Format from "@/core/format/format"
import { usePricingDisplayValue } from "@/pricing/pricingUtils"
import { CheckoutStoreQueryParams } from "@/product/checkout/store/useStartCheckout"
import ProductRegistrationForm from "@/product/register/ProductRegistrationForm"
import ProductRegistrationSection from "@/product/register/ProductRegistrationSection"
import { MembershipPlanRegistrationFragment$key } from "@/product/register/__generated__/MembershipPlanRegistrationFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type MembershipPlanRegistrationParams = {
  membershipPlanSlug?: string
}

interface Props {
  membershipPlanKey: MembershipPlanRegistrationFragment$key
  onPage?: boolean
}

function MembershipPlanRegistration({ membershipPlanKey, onPage }: Props) {
  const { registrationStep } = useQueryParams()
  const [startRegistration, setStartRegistration] = useState(false)
  const { inviteToken } = useQueryParams<CheckoutStoreQueryParams>()
  const activeOrganization = useActiveOrganization()

  const product = useFragment<MembershipPlanRegistrationFragment$key>(
    graphql`
      fragment MembershipPlanRegistrationFragment on Product {
        id
        myCheckout {
          id
        }
        registrationPricing {
          basePrice
          currency
          pricing {
            frequency
            ...pricingUtils_usePricingDisplayValue
          }
        }
        ...ProductRegistrationSectionFragment
        ...ProductOnboardingInfoSection_Product
      }
    `,
    membershipPlanKey
  )

  const classes = useStyles({ successStep: isRegistrationComplete(), onPage })
  const { pricing } = product.registrationPricing!

  const pricingValue = usePricingDisplayValue({
    pricingKey: pricing,
    currency: activeOrganization?.currency,
  })

  const total = pricing?.frequency
    ? pricingValue
    : Format.asCurrency(product.registrationPricing?.basePrice, {
        currency: activeOrganization?.currency,
      })

  if (!product) return null

  return (
    <div className={classes.container}>
      {startRegistration || inviteToken ? (
        <ProductRegistrationForm testid={"MembershipPlanRegistration"} />
      ) : (
        <>
          {product.registrationPricing &&
            Boolean(product.registrationPricing.basePrice) && (
              <DiscoText variant={"body-md-600"} marginBottom={2}>
                {`Price: ${total}`}
              </DiscoText>
            )}

          {/* Registration */}
          <ProductRegistrationSection
            experienceKey={product}
            setStartRegistration={setStartRegistration}
            testid={"MembershipPlanRegistration"}
          />
        </>
      )}
    </div>
  )

  function isRegistrationComplete() {
    const successStep = registrationStep === "success"
    if (!successStep) return false
    // Registration
    if (product.myCheckout?.id) return true
    return false
  }
}

interface StyleProps {
  successStep: boolean
  onPage?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: (props: StyleProps) => ({
    width: "360px",
    padding: theme.spacing(3),
    borderLeft: `1px solid ${theme.palette.groovy.neutral[100]}`,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    ...styleIf(props.onPage, {
      borderRadius: theme.measure.borderRadius.big,
      border: `1px solid ${theme.palette.groovy.neutral[100]}`,
      height: "fit-content",
      minWidth: "300px",
      width: "100%",
      maxWidth: "600px",
    }),

    ...styleIf(props.successStep, {
      gridColumn: "span 2",
      maxWidth: "unset",
    }),
  }),
}))

export const MembershipPlanRegistrationSkeleton: React.FC = () => {
  const classes = useStyles({ successStep: false })

  return (
    <div className={classes.container}>
      <>
        <DiscoTextSkeleton height={"60px"} width={"60%"} />
      </>
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(MembershipPlanRegistration),
  skeleton: MembershipPlanRegistrationSkeleton,
})
