import ChevronIcon from "@/core/ui/iconsax/linear/arrow-up-1.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MemberProgressBarLegendTooltip from "@/product/reports/MemberProgressBarLegendTooltip"
import Relay from "@/relay/relayUtils"
import CheckmarkIcon from "@assets/disco/icons/color-icons/checkmark-fill-light.svg"
import WarningIcon from "@assets/disco/icons/color-icons/warning-light.svg"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoDivider,
  DiscoIcon,
  DiscoIconKinds,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoProgressBar from "@disco-ui/progress/DiscoProgressBar"
import { Collapse, IconButton, Theme, useMediaQuery } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import pluralize from "pluralize"
import React, { useState } from "react"

export type MemberCurriculumProgressItem = {
  name: string
  completed: boolean
  released: boolean
}

interface Props {
  title: string
  completionLabel: string
  percentage: number
  icon: DiscoIconKinds
  color: string
  total: number
  bufferColor?: string
  items?: MemberCurriculumProgressItem[]
  emptyState?: React.ReactNode
}

function MemberCurriculumProgressBarSection({
  title,
  completionLabel,
  icon,
  color,
  total,
  bufferColor,
  percentage,
  items,
  emptyState,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const classes = useStyles({ isExpanded })
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  const unreleasedItems = items?.filter((item) => !item.released)
  const releasedItems = items?.filter((item) => item.released)
  const bufferProgress = items?.length
    ? ((items.length - unreleasedItems!.length) / items.length) * 100
    : 0

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          {!isXsScreen && (
            <>
              <div className={classes.buttonContainer}>
                <IconButton
                  data-testid={`CurriculumProgressSection.${title}.chevronButton`}
                  size={"small"}
                  className={classes.expandButton}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  <ChevronIcon height={24} width={24} />
                </IconButton>
              </div>

              {/* Icon */}
              <div className={classes.icon}>
                <DiscoIcon icon={icon} />
              </div>
            </>
          )}

          <div className={classes.progressContainer}>
            <div>
              <DiscoText variant={"body-lg-600"}>{title}</DiscoText>
            </div>

            <div className={classes.completionText}>
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {"Completed"}
              </DiscoText>

              <DiscoText
                data-testid={`CurriculumProgressSection.${title}.progress`}
                variant={"body-sm-700"}
                color={"groovy.grey.400"}
              >
                {completionLabel}
              </DiscoText>
            </div>
            <DiscoTooltip
              content={
                <MemberProgressBarLegendTooltip
                  progress={percentage}
                  bufferProgress={bufferProgress}
                  variant={"curriculum"}
                  color={color}
                  bufferColor={bufferColor}
                  total={total}
                />
              }
            >
              <div>
                <DiscoProgressBar
                  percentage={percentage}
                  bufferPercentage={bufferProgress}
                  height={"6px"}
                  color={color}
                  bufferColor={bufferColor}
                />
              </div>
            </DiscoTooltip>
          </div>
        </div>

        <div>
          <Collapse in={isExpanded}>
            <div data-testid={`CurriculumProgressSection.${title}.DropDownItems`}>
              {Boolean(releasedItems?.length) && (
                <DiscoDivider marginBottom={3} marginTop={3} />
              )}
              {Boolean(releasedItems?.length) || !emptyState ? (
                releasedItems?.map((item) => (
                  <div
                    key={item.name}
                    data-testid={`CurriculumProgressSection.${title}.DropDownItem`}
                    className={classes.completedItem}
                  >
                    <DiscoText variant={"body-lg-600"}>{item.name}</DiscoText>

                    <div className={classes.completedText}>
                      {item.completed ? (
                        <>
                          <CheckmarkIcon
                            width={24}
                            height={24}
                            className={classes.checkmark}
                          />
                          <DiscoText color={"text.secondary"}>{"Completed"}</DiscoText>
                        </>
                      ) : (
                        <>
                          <WarningIcon />
                          <DiscoText color={"text.secondary"}>{"Incomplete"}</DiscoText>
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <>{emptyState}</>
              )}

              {Boolean(unreleasedItems?.length) && (
                <>
                  <DiscoDivider marginBottom={3} marginTop={2} />
                  <DiscoText variant={"body-lg-600"} color={"text.disabled"}>{`${
                    unreleasedItems?.length
                  } Upcoming ${pluralize(title, unreleasedItems?.length)}`}</DiscoText>
                </>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </>
  )
}

type StyleProps = {
  isExpanded?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2.5),
    padding: theme.spacing(2.5),
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.big,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  progressContainer: {
    width: "100%",
  },
  icon: {
    width: "70px",
    minWidth: "70px",
    height: "70px",
    minHeight: "70px",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.medium,
    marginRight: theme.spacing(1.5),
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  completionText: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  expandButton: ({ isExpanded }: StyleProps) => ({
    display: "flex",
    border: `1px solid ${theme.palette.groovy.grey[400]}`,
    margin: theme.spacing(0, 3, 0, 1),
    transition: "transform 0.2s ease-in-out",
    transform: "rotate(90deg)",
    "& path": {
      color: theme.palette.groovy.grey[400],
    },

    ...styleIf(isExpanded, {
      transform: "rotate(180deg)",
    }),
  }),
  completedItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.75, 0.5),
  },
  completedText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  checkmark: {
    color: "none",

    "& rect": {
      color: theme.palette.groovy.green[100],
    },
  },
  iconSkeleton: {
    borderRadius: theme.measure.borderRadius.medium,
    margin: theme.spacing(0, 1.5),
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export const MemberCurriculumProgressBarSectionSkeleton: React.FC = () => {
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const classes = useStyles({ isExpanded: false })

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {!isXsScreen && (
          <>
            <div className={classes.buttonContainer}>
              <Skeleton variant={"circle"} width={24} height={24} />
            </div>

            <div className={classes.iconSkeleton}>
              <Skeleton width={70} height={70} />
            </div>
          </>
        )}

        <div className={classes.progressContainer}>
          <DiscoTextSkeleton variant={"body-lg-600"} width={150} />

          <div className={classes.completionText}>
            <DiscoTextSkeleton variant={"body-sm"} width={100} />

            <DiscoTextSkeleton variant={"body-sm-700"} width={50} />
          </div>

          <Skeleton variant={"rect"} width={"100%"} height={8} />
        </div>
      </div>
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: MemberCurriculumProgressBarSection,
  skeleton: MemberCurriculumProgressBarSectionSkeleton,
})
