import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { QueryParamTab } from "@disco-ui/tabs/DiscoQueryParamTabs"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { ArrayUtils } from "@utils/array/arrayUtils"
import React from "react"

export type MemberReportDrawerSubtab = "summary" | "curriculum" | "events" | "comments"

function MemberReportDrawerSubtabs() {
  const classes = useStyles()
  const curriculumLabel = useLabel("curriculum")
  const { connectedApps } = useConnectedProductApps()
  const isEventsAppConnected = connectedApps.has("events")
  const isCurriculumAppConnected = connectedApps.has("curriculum")
  const drawer = useGlobalDrawer("memberReport")
  const { productMembershipId } = drawer.params

  const tabs: QueryParamTab<GlobalDrawerParams<"memberReport">>[] = [
    {
      label: "Summary",
      params: { drawerTab: "summary", productMembershipId },
      testid: "summary",
    },
    ...ArrayUtils.spreadIf<QueryParamTab<GlobalDrawerParams<"memberReport">>>(
      {
        label: curriculumLabel.singular,
        params: { drawerTab: "curriculum", productMembershipId },
        testid: "curriculum",
      },
      isCurriculumAppConnected
    ),
    ...ArrayUtils.spreadIf<QueryParamTab<GlobalDrawerParams<"memberReport">>>(
      {
        label: "Events",
        params: {
          drawerTab: "events",
          productMembershipId,
        },
        testid: "events",
      },
      isEventsAppConnected
    ),
    {
      label: "Comments",
      params: { drawerTab: "comments", productMembershipId },
      testid: "comments",
    },
  ]

  return (
    <DiscoTabs
      testid={"MemberReportDrawerSubtabs"}
      routes={tabs}
      className={classes.subtabs}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  subtabs: {
    textAlign: "center",
    paddingTop: theme.spacing(1.5),

    [theme.breakpoints.down("xs")]: {
      "& a": {
        fontSize: "13px",
      },
    },
  },
}))

export default React.memo(MemberReportDrawerSubtabs)
